import { Multiselect } from '@/components/multiselect'
import { Checkbox } from '@/components/ui/checkbox'
import { Input } from '@/components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import {
  isBooleanControl,
  isDateControl,
  isDateTimeControl,
  isEnumControl,
  isIntegerControl,
  isNumberControl,
  isStringControl,
  isTimeControl,
  JsonFormsCellRendererRegistryEntry,
  JsonFormsRendererRegistryEntry,
  rankWith,
  schemaTypeIs,
  UISchemaElement,
  uiTypeIs
} from '@jsonforms/core'
import { JsonFormsDispatch, withJsonFormsControlProps, withJsonFormsLayoutProps } from '@jsonforms/react'
import { defaultTo, isNil } from 'ramda'
import { ComponentType } from 'react'

const InputControl = (props: any) => {
  return (
    <div className="p-1">
      <div className="text-xs font-medium mb-1">{props.label}</div>
      <Input
        className="text-xs"
        value={props.data}
        placeholder={props.description}
        onChange={(e) => props.handleChange(props.path, e.target.value)}
      />
      <div className="text-red-500 text-xs p-1">{props.errors}</div>
    </div>
  )
}

const NumberControl = (props: any) => {
  return (
    <div className="p-1">
      {props.label && <div className="text-xs font-medium mb-1">{props.label}</div>}
      <Input
        type="number"
        className="text-xs"
        value={props.data}
        placeholder={props.description}
        onChange={(e) => {
          const value = e.target.value
          if (isNil(value) || value === '') {
            props.handleChange(props.path, '')
          } else {
            props.handleChange(props.path, Number(value))
          }
        }}
      />
      <div className="text-red-500 text-xs p-1">{props.errors}</div>
    </div>
  )
}

const IntegerControl = (props: any) => {
  return (
    <div className="p-1 text-xs">
      <div className="font-medium mb-1">{props.label}</div>
      <Input
        type="number"
        className="text-xs"
        value={props.data}
        placeholder={props.description}
        onChange={(e) => {
          const value = e.target.value
          if (isNil(value) || value === '') {
            props.handleChange(props.path, '')
          } else {
            props.handleChange(props.path, Number(value))
          }
        }}
      />
      <div className="text-red-500 text-xs p-1">{props.errors}</div>
    </div>
  )
}

const DateControl = (props: any) => {
  return (
    <div className="p-1">
      <div className="text-xs font-medium mb-1">{props.label}</div>
      <Input
        type="date"
        className="w-min text-xs"
        value={props.data}
        placeholder={props.description}
        onChange={(e) => props.handleChange(props.path, e.target.value)}
      />
      <div className="text-red-500 text-xs p-1">{props.errors}</div>
    </div>
  )
}

const TimeControl = (props: any) => {
  return (
    <div className="p-1">
      <div className="text-xs font-medium mb-1">{props.label}</div>
      <Input
        type="time"
        className="w-min text-xs"
        value={props.data}
        placeholder={props.description}
        onChange={(e) => props.handleChange(props.path, e.target.value)}
      />
      <div className="text-red-500 text-xs p-1">{props.errors}</div>
    </div>
  )
}

const DateTimeControl = (props: any) => {
  const date = props.data?.split('T')[0]
  const time = props.data?.split('T')[1]

  function handleDateChange(e: any) {
    props.handleChange(props.path, `${e.target.value}T${time}`)
  }

  function handleTimeChange(e: any) {
    props.handleChange(props.path, `${date}T${e.target.value}`)
  }

  return (
    <div className="p-1 flex flex-col gap-2">
      <div className="text-xs font-medium mb-1">{props.description ?? props.label}</div>
      <Input type="Date" className="w-min text-xs" value={date} onChange={handleDateChange} />
      <Input type="time" className="w-min text-xs" value={time} onChange={handleTimeChange} />
      <div className="text-red-500 text-xs p-1">{props.errors}</div>
    </div>
  )
}

const EnumControl = (props: any) => {
  return (
    <div className="p-1">
      {props.label && <div className="text-xs font-medium mb-1">{props.label}</div>}
      <Select value={props.data} onValueChange={(value) => props.handleChange(props.path, value)}>
        <SelectTrigger className="text-xs">
          <SelectValue className="text-xs" placeholder={props.uischema.placeholder || 'Select'} />
        </SelectTrigger>
        <SelectContent>
          {defaultTo([], props.schema.enum).map((item: any) => (
            <SelectItem key={item} value={item} className="text-xs">
              {item}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <div className="text-red-500 text-xs p-1">{props.errors}</div>
    </div>
  )
}

const ArrayControl = (props: any) => {
  return (
    <div className="p-1">
      {props.label && <div className="text-xs font-medium mb-1">{props.label}</div>}
      <Multiselect
        placeholder="Select"
        value={props.data}
        options={
          props.uischema?.options?.suggestions?.map((item: string) => ({
            value: item,
            label: item
          })) ?? []
        }
        onChange={(value) => props.handleChange(props.path, value)}
      />
    </div>
  )
}

const ObjectControl: ComponentType<any> = (props) => {
  return (
    <div>
      <div className="text-xs font-medium mb-1">{props.label}</div>
      {Object.entries(props.schema.properties).map(([key, item]: [string, any]) => {
        if (item.type === 'number') {
          return (
            <NumberControl
              key={key}
              label={key}
              data={props.data[key]}
              path={props.path}
              errors={props.errors}
              handleChange={(path: string, value: string | number) =>
                props.handleChange(path, {
                  ...props.data,
                  [key]: value
                })
              }
            />
          )
        }
        if (item.type === 'string') {
          if (item.enum) {
            return (
              <EnumControl
                key={key}
                schema={{ enum: item.enum }}
                uischema={{ placeholder: props.uischema.placeholder }}
                data={props.data[key]}
                errors={props.errors}
                path={props.path}
                label={key}
                handleChange={(path: string, value: string) =>
                  props.handleChange(path, {
                    ...props.data,
                    [key]: value
                  })
                }
              />
            )
          }
          if (item.format === 'date') {
            return (
              <DateControl
                key={key}
                label={key}
                data={props.data[key]}
                path={props.path}
                errors={props.errors}
                handleChange={(path: string, value: string | number) =>
                  props.handleChange(path, {
                    ...props.data,
                    [key]: value
                  })
                }
              />
            )
          }

          return (
            <InputControl
              key={key}
              label={key}
              data={props.data[key]}
              path={props.path}
              errors={props.errors}
              handleChange={(path: string, value: string | number) =>
                props.handleChange(path, {
                  ...props.data,
                  [key]: value
                })
              }
            />
          )
        }
        return null
      })}
    </div>
  )
}

interface CheckboxControlProps {
  data: any
  handleChange(path: string, value: any): void
  path: string
  label: string
  description?: string
}

const CheckboxControl = ({ data, handleChange, path, description }: CheckboxControlProps) => {
  return (
    <div className="flex items-center gap-2 text-xs">
      <Checkbox id={path} checked={data} onCheckedChange={(value) => handleChange(path, value)} />
      <label htmlFor={path}>{description}</label>
    </div>
  )
}

const VerticalLayout: ComponentType<any> = ({
  schema,
  uischema,
  path,
  renderers = [] as JsonFormsRendererRegistryEntry[],
  cells = [] as JsonFormsCellRendererRegistryEntry[],
  enabled,
  visible
}) => {
  return (
    <div className="flex flex-col gap-2">
      {visible &&
        uischema.elements.map((child: UISchemaElement, index: number) => (
          <JsonFormsDispatch
            schema={schema}
            uischema={child}
            path={path}
            enabled={enabled}
            renderers={renderers}
            cells={cells}
            key={index}
          />
        ))}
    </div>
  )
}

const HorizontalLayout: ComponentType<any> = ({
  schema,
  uischema,
  path,
  renderers = [] as JsonFormsRendererRegistryEntry[],
  cells = [] as JsonFormsCellRendererRegistryEntry[],
  enabled,
  visible
}) => {
  return (
    <div className="flex flex-row gap-2 flex-wrap">
      {visible &&
        uischema.elements.map((child: UISchemaElement, index: number) => (
          <div key={index} className="w-full">
            <JsonFormsDispatch
              schema={schema}
              uischema={child}
              path={path}
              enabled={enabled}
              renderers={renderers}
              cells={cells}
            />
          </div>
        ))}
    </div>
  )
}

export const LebelRenderer = (props: any) => {
  return <div className="text-xs font-semibold mb-1">{props.uischema.text}</div>
}

export const InputRenderer = withJsonFormsControlProps(InputControl)

export const NumberRenderer = withJsonFormsControlProps(NumberControl)

export const IntegerRenderer = withJsonFormsControlProps(IntegerControl)

export const DateRenderer = withJsonFormsControlProps(DateControl)

export const TimeRenderer = withJsonFormsControlProps(TimeControl)

export const DateTimeRenderer = withJsonFormsControlProps(DateTimeControl)

export const EnumRenderer = withJsonFormsControlProps(EnumControl)

export const ArrayRenderer = withJsonFormsControlProps(ArrayControl)

export const ObjectRenderer = withJsonFormsControlProps(ObjectControl)

export const CheckboxRenderer = withJsonFormsControlProps(CheckboxControl)

export const VerticalLayoutRenderer = withJsonFormsLayoutProps(VerticalLayout)

export const HorizontalLayoutRenderer = withJsonFormsLayoutProps(HorizontalLayout)

export const labelTester = rankWith(3, uiTypeIs('Label'))

export const verticalLayoutTester = rankWith(1000, uiTypeIs('VerticalLayout'))

export const horizontalLayoutTester = rankWith(1000, uiTypeIs('HorizontalLayout'))

export const stringControlTester = rankWith(3, isStringControl)

export const integerControlTester = rankWith(3, isIntegerControl)

export const booleanControlTester = rankWith(3, isBooleanControl)

export const numberControlTester = rankWith(3, isNumberControl)

export const enumControlTester = rankWith(4, isEnumControl)

export const dateControlTester = rankWith(4, isDateControl)

export const dateTimeControlTester = rankWith(4, isDateTimeControl)

export const timeControlTester = rankWith(4, isTimeControl)

export const arrayControlTester = rankWith(3, schemaTypeIs('array'))

export const objectControlTester = rankWith(3, schemaTypeIs('object'))

export function getNodeDefaultValue(node: any): any {
  switch (node.type) {
    case 'string': {
      switch (node.format) {
        case 'date':
          return '2024-01-01'
        case 'date-time':
          return '2024-01-01T12:24'
        case 'time':
          return '12:24'
      }

      return ''
    }
    case 'boolean':
      return false
    case 'number':
    case 'integer':
      return 0
    case 'object': {
      const processedProperties = Object.entries(node.properties).map(([key, value]) => [
        key,
        getNodeDefaultValue(value)
      ])
      return Object.fromEntries(processedProperties)
    }
    case 'array':
      return []
    default:
      return ''
  }
}
