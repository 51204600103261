/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Body_count_section_form_submissions_api_dynamic_sections__id__count_post */
export interface BodyCountSectionFormSubmissionsApiDynamicSectionsIdCountPost {
  /** Form Data */
  form_data: object
}

/** Body_create_data_group_api_webhooks_snowflake_data_group_post */
export interface BodyCreateDataGroupApiWebhooksSnowflakeDataGroupPost {
  /** Name */
  name: string
}

/** Body_create_data_set_api_webhooks_snowflake_data_set_post */
export interface BodyCreateDataSetApiWebhooksSnowflakeDataSetPost {
  /** Name */
  name: string
  /**
   * Data Group Id
   * @format uuid
   */
  data_group_id: string
}

/** Body_create_dynamic_report_api_dynamic_reports_post */
export interface BodyCreateDynamicReportApiDynamicReportsPost {
  /** Data Set Ids */
  data_set_ids: string[]
}

/** Body_create_dynamic_section_api_dynamic_sections_post */
export interface BodyCreateDynamicSectionApiDynamicSectionsPost {
  /** Prompt */
  prompt: string
  /**
   * Dynamic Report Id
   * @format uuid
   */
  dynamic_report_id: string
}

/** Body_create_scheduled_research_plan_api_scheduled_research_plans_post */
export interface BodyCreateScheduledResearchPlanApiScheduledResearchPlansPost {
  /**
   * Research Plan Id
   * @format uuid
   */
  research_plan_id: string
  /** Schedule */
  schedule: string
  /** Timezone */
  timezone: string
}

/** Body_snowflake_new_table_api_webhooks_snowflake_data_table_post */
export interface BodySnowflakeNewTableApiWebhooksSnowflakeDataTablePost {
  /**
   * Data Set Id
   * @format uuid
   */
  data_set_id: string
  /** Sf Db Name */
  sf_db_name: string
  /** Sf Schema Name */
  sf_schema_name: string
  /** Sf Table Name */
  sf_table_name: string
}

/** Body_submit_section_form_api_dynamic_sections__id__submit_post */
export interface BodySubmitSectionFormApiDynamicSectionsIdSubmitPost {
  /** Form Data */
  form_data: object
}

/** Body_update_dynamic_report_api_dynamic_reports__report_id__put */
export interface BodyUpdateDynamicReportApiDynamicReportsReportIdPut {
  /** Data Set Ids */
  data_set_ids: string[]
}

/** Body_update_dynamic_section_api_dynamic_sections__id__put */
export interface BodyUpdateDynamicSectionApiDynamicSectionsIdPut {
  /** Prompt */
  prompt: string
}

/** Body_update_rank_order_api_dynamic_sections__id__rank_order_put */
export interface BodyUpdateRankOrderApiDynamicSectionsIdRankOrderPut {
  /** Rank Order */
  rank_order: number
}

/** Body_update_report_title_api_dynamic_reports__report_id__title_put */
export interface BodyUpdateReportTitleApiDynamicReportsReportIdTitlePut {
  /** Title */
  title: string
}

/** Body_update_scheduled_research_plan_api_scheduled_research_plans__scheduled_research_plan_id__put */
export interface BodyUpdateScheduledResearchPlanApiScheduledResearchPlansScheduledResearchPlanIdPut {
  /** Schedule */
  schedule: string
  /** Timezone */
  timezone: string
}

/** Body_update_section_title_api_dynamic_sections__id__title_put */
export interface BodyUpdateSectionTitleApiDynamicSectionsIdTitlePut {
  /** Title */
  title: string
}

/** Body_upload_source_file_api_sources__source_id__files_post */
export interface BodyUploadSourceFileApiSourcesSourceIdFilesPost {
  /**
   * File
   * @format binary
   */
  file: File
  /** Metadata */
  metadata?: string | null
}

/** DataGroup */
export interface DataGroup {
  /**
   * Id
   * Unique identifier
   */
  id?: string | null
  /**
   * Created At
   * Creation datetime
   */
  created_at?: string | null
  /**
   * Updated At
   * Update datetime
   */
  updated_at?: string | null
  /** Name */
  name: string
}

/** DataSetResponse */
export interface DataSetResponse {
  /**
   * Id
   * Unique identifier
   */
  id?: string | null
  /**
   * Created At
   * Creation datetime
   */
  created_at?: string | null
  /**
   * Updated At
   * Update datetime
   */
  updated_at?: string | null
  /** Name */
  name: string
  /**
   * Data Group Id
   * @format uuid
   */
  data_group_id: string
  /** Last Altered */
  last_altered?: string | null
  /** Row Count */
  row_count?: number | null
}

/** DataTypeEnum */
export enum DataTypeEnum {
  Chart = 'chart',
  Table = 'table',
  Text = 'text'
}

/** DynamicReport */
export interface DynamicReport {
  /**
   * Id
   * Unique identifier
   */
  id?: string | null
  /**
   * Created At
   * Creation datetime
   */
  created_at?: string | null
  /**
   * Updated At
   * Update datetime
   */
  updated_at?: string | null
  /** Title */
  title: string
  /** Data Set Ids */
  data_set_ids: string[]
}

/** DynamicSection */
export interface DynamicSection {
  /**
   * Id
   * Unique identifier
   */
  id?: string | null
  /**
   * Created At
   * Creation datetime
   */
  created_at?: string | null
  /**
   * Updated At
   * Update datetime
   */
  updated_at?: string | null
  /**
   * Dynamic Report Id
   * @format uuid
   */
  dynamic_report_id: string
  /** Title */
  title?: string | null
  data_type?: DataTypeEnum | null
  /** Data Content */
  data_content?: object | null
  /** Form Json Schema */
  form_json_schema?: object | null
  /** Form Ui Schema */
  form_ui_schema?: object | null
  /** Rank Order */
  rank_order?: number | null
  /** Prompt */
  prompt?: string | null
  /** Data Set Ids */
  data_set_ids?: string[] | null
  /** Sql Query */
  sql_query?: string | null
  /** Form Data */
  form_data?: object | null
  /** @default "PENDING" */
  status?: DynamicSectionStatusEnum
}

/** DynamicSectionStatusEnum */
export enum DynamicSectionStatusEnum {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR'
}

/** FileType */
export enum FileType {
  File = 'file',
  Folder = 'folder',
  Url = 'url'
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[]
}

/** MessageMetadata */
export interface MessageMetadata {
  /** Research Plan Id */
  research_plan_id?: string | null
  /** New Project Title */
  new_project_title?: string | null
}

/** Project */
export interface Project {
  /**
   * Id
   * Unique identifier
   */
  id?: string | null
  /**
   * Created At
   * Creation datetime
   */
  created_at?: string | null
  /**
   * Updated At
   * Update datetime
   */
  updated_at?: string | null
  /** Title */
  title?: string | null
  /**
   * Research Plans
   * @default []
   */
  research_plans?: ResearchPlan[]
  /**
   * Research Reports
   * @default []
   */
  research_reports?: ResearchReport[]
  /**
   * Messages
   * @default []
   */
  messages?: ProjectMessage[]
}

/** ProjectMessage */
export interface ProjectMessage {
  /**
   * Id
   * Unique identifier
   */
  id?: string | null
  /**
   * Created At
   * Creation datetime
   */
  created_at?: string | null
  /**
   * Updated At
   * Update datetime
   */
  updated_at?: string | null
  /**
   * Project Id
   * @format uuid
   */
  project_id: string
  /** Content */
  content: string
  role: ProjectMessageRoleEnum
  status: ProjectMessageStatusEnum
  meta_data?: MessageMetadata | null
}

/** ProjectMessageRoleEnum */
export enum ProjectMessageRoleEnum {
  User = 'user',
  Assistant = 'assistant',
  ResearchAssistant = 'research_assistant'
}

/** ProjectMessageStatusEnum */
export enum ProjectMessageStatusEnum {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

/** ReportAttachment */
export interface ReportAttachment {
  /** Url */
  url: string
  /** Filetype */
  filetype: string
}

/** ReportExportResponse */
export interface ReportExportResponse {
  /**
   * S3 Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  s3_url: string
}

/** ReportSource */
export interface ReportSource {
  /** Order */
  order: number
  /** Content */
  content: string
  /** Url */
  url?: string | null
}

/** ResearchPlan */
export interface ResearchPlan {
  /**
   * Id
   * Unique identifier
   */
  id?: string | null
  /**
   * Created At
   * Creation datetime
   */
  created_at?: string | null
  /**
   * Updated At
   * Update datetime
   */
  updated_at?: string | null
  /**
   * Project Id
   * @format uuid
   */
  project_id: string
  /** Content */
  content: string
  /** User Message */
  user_message?: string | null
  status: ResearchPlanStatusEnum
  category?: ResearchPlanCategoryEnum | null
  /** Started At */
  started_at?: string | null
  /** Cancelled At */
  cancelled_at?: string | null
  /** Completed At */
  completed_at?: string | null
}

/** ResearchPlanCategoryEnum */
export enum ResearchPlanCategoryEnum {
  DataAnalysis = 'Data & Analysis',
  DiscoveryInsights = 'Discovery & Insights',
  FundamentalStockAnalysis = 'Fundamental & Stock Analysis',
  WebScraping = 'Web Scraping'
}

/** ResearchPlanStatusEnum */
export enum ResearchPlanStatusEnum {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED'
}

/** ResearchReport */
export interface ResearchReport {
  /**
   * Id
   * Unique identifier
   */
  id?: string | null
  /**
   * Created At
   * Creation datetime
   */
  created_at?: string | null
  /**
   * Updated At
   * Update datetime
   */
  updated_at?: string | null
  /**
   * Project Id
   * @format uuid
   */
  project_id: string
  /**
   * Research Plan Id
   * @format uuid
   */
  research_plan_id: string
  /** Title */
  title: string
  /** Content */
  content: string
  /** Attachments */
  attachments?: ReportAttachment[] | null
  /** Sources */
  sources?: ReportSource[] | null
}

/** ResearchSuggestion */
export interface ResearchSuggestion {
  /**
   * Id
   * Unique identifier
   */
  id?: string | null
  /**
   * Created At
   * Creation datetime
   */
  created_at?: string | null
  /**
   * Updated At
   * Update datetime
   */
  updated_at?: string | null
  /** Title */
  title: string
  /** Content */
  content: string
  /** Category */
  category?: string | null
  /**
   * Rank
   * @default 0
   */
  rank?: number
}

/** ScheduledResearchPlan */
export interface ScheduledResearchPlan {
  /**
   * Id
   * Unique identifier
   */
  id?: string | null
  /**
   * Created At
   * Creation datetime
   */
  created_at?: string | null
  /**
   * Updated At
   * Update datetime
   */
  updated_at?: string | null
  /**
   * Project Id
   * @format uuid
   */
  project_id: string
  /**
   * Parent Research Plan Id
   * @format uuid
   */
  parent_research_plan_id: string
  /** Last Research Plan Id */
  last_research_plan_id?: string | null
  /**
   * Schedule
   * Crontab schedule string
   */
  schedule: string
  parent_research_plan: ResearchPlan
  last_research_plan?: ResearchPlan | null
}

/** SectionExportResponse */
export interface SectionExportResponse {
  /**
   * S3 Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  s3_url: string
}

/** SnowflakeConnectionStatus */
export interface SnowflakeConnectionStatus {
  /** Status */
  status: 'succeeded' | 'failed'
}

/** SnowflakeCredentials */
export interface SnowflakeCredentials {
  /** Account */
  account: string
  /** User */
  user: string
  /** Database */
  database?: string | null
  /** Warehouse */
  warehouse?: string | null
  /** Password */
  password: string
}

/** SnowflakeCredentialsReadOnly */
export interface SnowflakeCredentialsReadOnly {
  /** Account */
  account: string
  /** User */
  user: string
  /** Database */
  database?: string | null
  /** Warehouse */
  warehouse?: string | null
}

/** SnowflakeSourceInput */
export interface SnowflakeSourceInput {
  /**
   * Name
   * @default "Snowflake"
   */
  name?: string | null
  credentials: SnowflakeCredentials
}

/** SnowflakeSourceReadOnly */
export interface SnowflakeSourceReadOnly {
  /**
   * Id
   * Unique identifier
   */
  id?: string | null
  /**
   * Created At
   * Creation datetime
   */
  created_at?: string | null
  /**
   * Updated At
   * Update datetime
   */
  updated_at?: string | null
  /**
   * Name
   * @default "Snowflake"
   */
  name?: string | null
  /** @default "sql" */
  type?: SourceType
  meta_data?: SnowflakeCredentialsReadOnly | null
}

/** Source */
export interface Source {
  /**
   * Id
   * Unique identifier
   */
  id?: string | null
  /**
   * Created At
   * Creation datetime
   */
  created_at?: string | null
  /**
   * Updated At
   * Update datetime
   */
  updated_at?: string | null
  /** Name */
  name: string
  type: SourceType
  /**
   * Order
   * @default 0
   */
  order?: number | null
  /**
   * Is Experimental
   * @default false
   */
  is_experimental?: boolean
  meta_data?: SourceMetadata | null
}

/** SourceFile */
export interface SourceFile {
  /**
   * Id
   * Unique identifier
   */
  id?: string | null
  /**
   * Created At
   * Creation datetime
   */
  created_at?: string | null
  /**
   * Updated At
   * Update datetime
   */
  updated_at?: string | null
  /**
   * Source Id
   * @format uuid
   */
  source_id: string
  /** Name */
  name: string
  /** Path */
  path: string
  type: FileType
  meta_data?: SourceFileMetadata | null
  /**
   * Parent Folders
   * @default []
   */
  parent_folders?: string[]
}

/** SourceFileMetadata */
export interface SourceFileMetadata {
  /** File Count */
  file_count?: number | null
  /** Apideck Id */
  apideck_id?: string | null
  /** Description */
  description?: string | null
  /** File Size */
  file_size?: number | null
  /** Content Type */
  content_type?: string | null
  /** File Extension */
  file_extension?: string | null
}

/** SourceMetadata */
export interface SourceMetadata {
  /** File Count */
  file_count?: number | null
  /** Apideck Id */
  apideck_id?: string | null
  /** Description */
  description?: string | null
}

/** SourceSelectionUpdate */
export interface SourceSelectionUpdate {
  /** Include */
  include: string[]
  /** Exclude */
  exclude: string[]
}

/** SourceType */
export enum SourceType {
  Uploads = 'uploads',
  Public = 'public',
  Drive = 'drive',
  Sql = 'sql'
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[]
  /** Message */
  msg: string
  /** Error Type */
  type: string
}

/** VaultSession */
export interface VaultSession {
  /** Token */
  token: string
}

export interface ListProjectsApiProjectsGetParams {
  /**
   * Sort By
   * Field to sort by (title or updated_at)
   */
  sort_by?: string | null
  /**
   * Sort Order
   * Sort order (asc or desc)
   */
  sort_order?: string | null
  /**
   * Page
   * Page number
   * @default 1
   */
  page?: number | null
}

/** Response List Projects Api Projects Get */
export type ListProjectsApiProjectsGetData = Project[]

export type ListProjectsApiProjectsGetError = HTTPValidationError

export interface CreateProjectApiProjectsPostParams {
  /** Title */
  title?: string | null
}

export type CreateProjectApiProjectsPostData = Project

export type CreateProjectApiProjectsPostError = HTTPValidationError

/** Project Ids */
export type DeleteProjectsApiProjectsDeletePayload = string[]

export type DeleteProjectsApiProjectsDeleteData = any

export type DeleteProjectsApiProjectsDeleteError = HTTPValidationError

export interface SearchProjectsApiProjectsSearchGetParams {
  /**
   * Query
   * Search query
   */
  query?: string
  /**
   * Sort By
   * Field to sort by (title or updated_at)
   */
  sort_by?: string | null
  /**
   * Sort Order
   * Sort order (asc or desc)
   */
  sort_order?: string | null
  /**
   * Page
   * Page number
   * @default 1
   */
  page?: number | null
}

/** Response Search Projects Api Projects Search Get */
export type SearchProjectsApiProjectsSearchGetData = Project[]

export type SearchProjectsApiProjectsSearchGetError = HTTPValidationError

export type GetProjectApiProjectsProjectIdGetData = Project

export type GetProjectApiProjectsProjectIdGetError = HTTPValidationError

export interface UpdateProjectApiProjectsProjectIdPutParams {
  /** Title */
  title: string
  /**
   * Project Id
   * @format uuid
   */
  projectId: string
}

export type UpdateProjectApiProjectsProjectIdPutData = Project

export type UpdateProjectApiProjectsProjectIdPutError = HTTPValidationError

export type DeleteProjectApiProjectsProjectIdDeleteData = any

export type DeleteProjectApiProjectsProjectIdDeleteError = HTTPValidationError

export type DuplicateProjectApiProjectsProjectIdDuplicatePostData = Project

export type DuplicateProjectApiProjectsProjectIdDuplicatePostError = HTTPValidationError

export interface CreateMessageResponseStreamApiProjectsProjectIdMessagesStreamPostParams {
  /** User Message */
  user_message: string
  /** Project Id */
  projectId: string
}

export type CreateMessageResponseStreamApiProjectsProjectIdMessagesStreamPostData = ProjectMessage

export type CreateMessageResponseStreamApiProjectsProjectIdMessagesStreamPostError = HTTPValidationError

export interface CreateMessageResponseApiProjectsProjectIdMessagesPostParams {
  /**
   * User Message
   * @default ""
   */
  user_message?: string
  /**
   * Retry
   * Retry a research plan by providing the research plan ID
   */
  retry?: string | null
  /** Project Id */
  projectId: string
}

export type CreateMessageResponseApiProjectsProjectIdMessagesPostData = ProjectMessage

export type CreateMessageResponseApiProjectsProjectIdMessagesPostError = HTTPValidationError

/** Response Fetch Project Messages Api Projects  Project Id  Messages Get */
export type FetchProjectMessagesApiProjectsProjectIdMessagesGetData = ProjectMessage[]

export type FetchProjectMessagesApiProjectsProjectIdMessagesGetError = HTTPValidationError

export type GetResearchPlanApiResearchPlansPlanIdGetData = ResearchPlan

export type GetResearchPlanApiResearchPlansPlanIdGetError = HTTPValidationError

export type StartResearchPlanApiResearchPlansResearchPlanIdStartPostData = any

export type StartResearchPlanApiResearchPlansResearchPlanIdStartPostError = HTTPValidationError

export type StopResearchPlanApiResearchPlansResearchPlanIdStopPostData = any

export type StopResearchPlanApiResearchPlansResearchPlanIdStopPostError = HTTPValidationError

export interface ListProjectReportsApiProjectsProjectIdReportsGetParams {
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Page Size
   * @min 1
   * @max 100
   * @default 10
   */
  page_size?: number
  /**
   * Project Id
   * @format uuid
   */
  projectId: string
}

/** Response List Project Reports Api Projects  Project Id  Reports Get */
export type ListProjectReportsApiProjectsProjectIdReportsGetData = ResearchReport[]

export type ListProjectReportsApiProjectsProjectIdReportsGetError = HTTPValidationError

export type GetResearchReportApiResearchReportsReportIdGetData = ResearchReport

export type GetResearchReportApiResearchReportsReportIdGetError = HTTPValidationError

/** Response Get Project Research Suggestions Api Projects  Project Id  Suggestions Get */
export type GetProjectResearchSuggestionsApiProjectsProjectIdSuggestionsGetData = ResearchSuggestion[]

export type GetProjectResearchSuggestionsApiProjectsProjectIdSuggestionsGetError = HTTPValidationError

/** Response Get Scheduled Research Plans Api Scheduled Research Plans Get */
export type GetScheduledResearchPlansApiScheduledResearchPlansGetData = ScheduledResearchPlan[]

export type CreateScheduledResearchPlanApiScheduledResearchPlansPostData = ScheduledResearchPlan

export type CreateScheduledResearchPlanApiScheduledResearchPlansPostError = HTTPValidationError

/** Plan Ids */
export type BulkDeleteScheduledResearchPlansApiScheduledResearchPlansDeletePayload = string[]

export type BulkDeleteScheduledResearchPlansApiScheduledResearchPlansDeleteData = any

export type BulkDeleteScheduledResearchPlansApiScheduledResearchPlansDeleteError = HTTPValidationError

export type GetScheduledResearchPlanApiScheduledResearchPlansScheduledResearchPlanIdGetData = ScheduledResearchPlan

export type GetScheduledResearchPlanApiScheduledResearchPlansScheduledResearchPlanIdGetError = HTTPValidationError

export type UpdateScheduledResearchPlanApiScheduledResearchPlansScheduledResearchPlanIdPutData = ScheduledResearchPlan

export type UpdateScheduledResearchPlanApiScheduledResearchPlansScheduledResearchPlanIdPutError = HTTPValidationError

export type DeleteScheduledResearchPlanApiScheduledResearchPlansScheduledResearchPlanIdDeleteData = any

export type DeleteScheduledResearchPlanApiScheduledResearchPlansScheduledResearchPlanIdDeleteError = HTTPValidationError

/** Response Get Sources Api Sources Get */
export type GetSourcesApiSourcesGetData = Source[]

export interface UploadSourceFileApiSourcesSourceIdFilesPostParams {
  /**
   * Folder Path
   * @default "/"
   */
  folder_path?: string
  /**
   * Source Id
   * @format uuid
   */
  sourceId: string
}

export type UploadSourceFileApiSourcesSourceIdFilesPostData = SourceFile

export type UploadSourceFileApiSourcesSourceIdFilesPostError = HTTPValidationError

export interface GetSourceFilesApiSourcesSourceIdFilesGetParams {
  /**
   * Folder Path
   * @default "/"
   */
  folder_path?: string
  /**
   * Source Id
   * @format uuid
   */
  sourceId: string
}

/** Response Get Source Files Api Sources  Source Id  Files Get */
export type GetSourceFilesApiSourcesSourceIdFilesGetData = SourceFile[]

export type GetSourceFilesApiSourcesSourceIdFilesGetError = HTTPValidationError

export interface DeleteSourceFileEndpointApiSourcesSourceIdFilesDeleteParams {
  /** File Path */
  file_path: string
  /**
   * Source Id
   * @format uuid
   */
  sourceId: string
}

export type DeleteSourceFileEndpointApiSourcesSourceIdFilesDeleteData = any

export type DeleteSourceFileEndpointApiSourcesSourceIdFilesDeleteError = HTTPValidationError

export interface SearchSourceFilesApiSourcesSourceIdFilesSearchGetParams {
  /** Query */
  query?: string
  /**
   * Source Id
   * @format uuid
   */
  sourceId: string
}

/** Response Search Source Files Api Sources  Source Id  Files Search Get */
export type SearchSourceFilesApiSourcesSourceIdFilesSearchGetData = SourceFile[]

export type SearchSourceFilesApiSourcesSourceIdFilesSearchGetError = HTTPValidationError

export interface DownloadSourceFileByPathApiFilesDownloadGetParams {
  /** Path */
  path: string
}

/** Response Download Source File By Path Api Files Download Get */
export type DownloadSourceFileByPathApiFilesDownloadGetData = string

export type DownloadSourceFileByPathApiFilesDownloadGetError = HTTPValidationError

export interface CreateSourceFolderEndpointApiSourcesSourceIdFoldersPostParams {
  /** Parent Folder */
  parent_folder: string
  /**
   * Title
   * @default ""
   */
  title?: string | null
  /**
   * Source Id
   * @format uuid
   */
  sourceId: string
}

export type CreateSourceFolderEndpointApiSourcesSourceIdFoldersPostData = SourceFile

export type CreateSourceFolderEndpointApiSourcesSourceIdFoldersPostError = HTTPValidationError

export interface DeleteSourceFolderApiSourcesSourceIdFoldersDeleteParams {
  /** Folder Path */
  folder_path: string
  /**
   * Source Id
   * @format uuid
   */
  sourceId: string
}

export type DeleteSourceFolderApiSourcesSourceIdFoldersDeleteData = any

export type DeleteSourceFolderApiSourcesSourceIdFoldersDeleteError = HTTPValidationError

export interface GetSourceSelectionsApiSourcesSourceIdSelectionsGetParams {
  /**
   * Project Id
   * @format uuid
   */
  project_id: string
  /**
   * Source Id
   * @format uuid
   */
  sourceId: string
}

/** Response Get Source Selections Api Sources  Source Id  Selections Get */
export type GetSourceSelectionsApiSourcesSourceIdSelectionsGetData = Record<string, string[]>

export type GetSourceSelectionsApiSourcesSourceIdSelectionsGetError = HTTPValidationError

export interface CreateSourceSelectionsApiSourcesSourceIdSelectionsPutParams {
  /**
   * Project Id
   * @format uuid
   */
  project_id: string
  /**
   * Source Id
   * @format uuid
   */
  sourceId: string
}

/** Response Create Source Selections Api Sources  Source Id  Selections Put */
export type CreateSourceSelectionsApiSourcesSourceIdSelectionsPutData = Record<string, string[]>

export type CreateSourceSelectionsApiSourcesSourceIdSelectionsPutError = HTTPValidationError

export type GetSnowflakeSourceApiSourcesSnowflakeGetData = SnowflakeSourceReadOnly

export type CreateOrUpdateSnowflakeSourceApiSourcesSnowflakePutData = SnowflakeSourceReadOnly

export type CreateOrUpdateSnowflakeSourceApiSourcesSnowflakePutError = HTTPValidationError

export type TestSnowflakeConnectionApiSourcesSnowflakeTestConnectionPostData = SnowflakeConnectionStatus

export type TestSnowflakeConnectionApiSourcesSnowflakeTestConnectionPostError = HTTPValidationError

export type GetVaultTokenApiSourcesDriveVaultTokenPostData = VaultSession

export interface SearchTickersApiTickersGetParams {
  /**
   * Search
   * @default ""
   */
  search?: string
  /**
   * Limit
   * @default 5
   */
  limit?: number
}

/** Response Search Tickers Api Tickers  Get */
export type SearchTickersApiTickersGetData = string[]

export type SearchTickersApiTickersGetError = HTTPValidationError

export type ReceiveWebhookApiWebhooksPostData = any

export type StripeWebhookApiWebhooksStripePostData = any

export type SnowflakeNewTableApiWebhooksSnowflakeDataTablePostData = any

export type SnowflakeNewTableApiWebhooksSnowflakeDataTablePostError = HTTPValidationError

export type CreateDataGroupApiWebhooksSnowflakeDataGroupPostData = any

export type CreateDataGroupApiWebhooksSnowflakeDataGroupPostError = HTTPValidationError

export type CreateDataSetApiWebhooksSnowflakeDataSetPostData = any

export type CreateDataSetApiWebhooksSnowflakeDataSetPostError = HTTPValidationError

export type SubscriptionCheckApiStripeSubscriptionCheckPostData = any

export type CustomerPortalApiStripeCustomerPortalPostData = any

export interface ListDataGroupsApiDataGroupsGetParams {
  /**
   * Sort By
   * Field to sort by (name or updated_at)
   */
  sort_by?: string | null
  /**
   * Sort Order
   * Sort order (asc or desc)
   */
  sort_order?: string | null
  /**
   * Page
   * Page number
   * @default 1
   */
  page?: number | null
}

/** Response List Data Groups Api Data Groups Get */
export type ListDataGroupsApiDataGroupsGetData = DataGroup[]

export type ListDataGroupsApiDataGroupsGetError = HTTPValidationError

export interface SearchDataGroupsApiDataGroupsSearchGetParams {
  /**
   * Query
   * Search query
   */
  query?: string
  /**
   * Sort By
   * Field to sort by (name or updated_at)
   */
  sort_by?: string | null
  /**
   * Sort Order
   * Sort order (asc or desc)
   */
  sort_order?: string | null
  /**
   * Page
   * Page number
   * @default 1
   */
  page?: number | null
}

/** Response Search Data Groups Api Data Groups Search Get */
export type SearchDataGroupsApiDataGroupsSearchGetData = DataGroup[]

export type SearchDataGroupsApiDataGroupsSearchGetError = HTTPValidationError

export interface GetDataSetsApiDataSetsGetParams {
  /**
   * Page
   * @default 1
   */
  page?: number
  /** Data Group Id */
  data_group_id?: string | null
}

/** Response Get Data Sets Api Data Sets Get */
export type GetDataSetsApiDataSetsGetData = DataSetResponse[]

export type GetDataSetsApiDataSetsGetError = HTTPValidationError

export type GetDataSetApiDataSetsDataSetIdGetData = DataSetResponse

export type GetDataSetApiDataSetsDataSetIdGetError = HTTPValidationError

export type CreateDynamicReportApiDynamicReportsPostData = DynamicReport

export type CreateDynamicReportApiDynamicReportsPostError = HTTPValidationError

export interface ListDynamicReportsApiDynamicReportsGetParams {
  /**
   * Sort By
   * Field to sort by (title or updated_at)
   */
  sort_by?: string | null
  /**
   * Sort Order
   * Sort order (asc or desc)
   */
  sort_order?: string | null
  /**
   * Page
   * Page number
   * @default 1
   */
  page?: number | null
}

/** Response List Dynamic Reports Api Dynamic Reports Get */
export type ListDynamicReportsApiDynamicReportsGetData = DynamicReport[]

export type ListDynamicReportsApiDynamicReportsGetError = HTTPValidationError

export type GetDynamicReportApiDynamicReportsReportIdGetData = DynamicReport

export type GetDynamicReportApiDynamicReportsReportIdGetError = HTTPValidationError

export type UpdateDynamicReportApiDynamicReportsReportIdPutData = DynamicReport

export type UpdateDynamicReportApiDynamicReportsReportIdPutError = HTTPValidationError

export type DeleteDynamicReportApiDynamicReportsReportIdDeleteData = any

export type DeleteDynamicReportApiDynamicReportsReportIdDeleteError = HTTPValidationError

export type ExportReportToPdfApiDynamicReportsIdExportPdfPostData = ReportExportResponse

export type ExportReportToPdfApiDynamicReportsIdExportPdfPostError = HTTPValidationError

export type UpdateReportTitleApiDynamicReportsReportIdTitlePutData = DynamicReport

export type UpdateReportTitleApiDynamicReportsReportIdTitlePutError = HTTPValidationError

export type CreateDynamicSectionApiDynamicSectionsPostData = DynamicSection

export type CreateDynamicSectionApiDynamicSectionsPostError = HTTPValidationError

export interface ListDynamicSectionsApiDynamicSectionsGetParams {
  /**
   * Dynamic Report Id
   * @format uuid
   */
  dynamic_report_id: string
}

/** Response List Dynamic Sections Api Dynamic Sections Get */
export type ListDynamicSectionsApiDynamicSectionsGetData = DynamicSection[]

export type ListDynamicSectionsApiDynamicSectionsGetError = HTTPValidationError

export type GetDynamicSectionApiDynamicSectionsIdGetData = DynamicSection

export type GetDynamicSectionApiDynamicSectionsIdGetError = HTTPValidationError

export type UpdateDynamicSectionApiDynamicSectionsIdPutData = DynamicSection

export type UpdateDynamicSectionApiDynamicSectionsIdPutError = HTTPValidationError

export type DeleteDynamicSectionApiDynamicSectionsIdDeleteData = any

export type DeleteDynamicSectionApiDynamicSectionsIdDeleteError = HTTPValidationError

export type UpdateRankOrderApiDynamicSectionsIdRankOrderPutData = DynamicSection

export type UpdateRankOrderApiDynamicSectionsIdRankOrderPutError = HTTPValidationError

export type SubmitSectionFormApiDynamicSectionsIdSubmitPostData = any

export type SubmitSectionFormApiDynamicSectionsIdSubmitPostError = HTTPValidationError

/** Response Count Section Form Submissions Api Dynamic Sections  Id  Count Post */
export type CountSectionFormSubmissionsApiDynamicSectionsIdCountPostData = number

export type CountSectionFormSubmissionsApiDynamicSectionsIdCountPostError = HTTPValidationError

export type DuplicateDynamicSectionApiDynamicSectionsIdDuplicatePostData = DynamicSection

export type DuplicateDynamicSectionApiDynamicSectionsIdDuplicatePostError = HTTPValidationError

export type UpdateSectionTitleApiDynamicSectionsIdTitlePutData = DynamicSection

export type UpdateSectionTitleApiDynamicSectionsIdTitlePutError = HTTPValidationError

export type ExportSectionToCsvApiDynamicSectionsIdExportCsvPostData = SectionExportResponse

export type ExportSectionToCsvApiDynamicSectionsIdExportCsvPostError = HTTPValidationError

/** Response Health Health  Get */
export type HealthHealthGetData = Record<string, string>
