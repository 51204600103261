import { AuthGuard } from '@/components/auth-guard'
import { SourcesPageReadOnlyContext } from '@/contexts/sources-page-readonly'
import { CreateProjectPage } from '@/pages/create-project'
import { EmailVerificationPage } from '@/pages/email-verification'
import { ForgotPasswordPage } from '@/pages/forgot-password'
import { HomePage } from '@/pages/home'
import { LoginPage } from '@/pages/login'
import { LogoutPage } from '@/pages/logout'
import { ProjectPage } from '@/pages/project'
import { ScheduledResearchPlansPage } from '@/pages/scheduled-research-plans'
import { SettingsPage } from '@/pages/settings'
import { SignupPage } from '@/pages/signup'
import { SourcesPage } from '@/pages/sources'
import { DynamicFormPage } from '@/pages/dynamic-form'
import { routePath } from '@/router/route-path'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { DynamicFormResultPage } from '@/pages/dynamic-form-result'

export function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Navigate to={routePath.home} />} />
        <Route path="/" element={<Navigate to={routePath.home} />} />
        <Route
          path={routePath.projects}
          element={
            <AuthGuard>
              <HomePage />
            </AuthGuard>
          }
        />
        <Route
          path={routePath.scheduledResearchPlan}
          element={
            <AuthGuard>
              <ScheduledResearchPlansPage />
            </AuthGuard>
          }
        />
        <Route path={routePath.home} element={<Navigate to={routePath.createProject} />} />
        <Route
          path={routePath.emailVerification}
          element={
            <AuthGuard>
              <EmailVerificationPage />
            </AuthGuard>
          }
        />
        <Route
          path={routePath.createProject}
          element={
            <AuthGuard>
              <CreateProjectPage />
            </AuthGuard>
          }
        />
        <Route
          path={`${routePath.project}/:projectId/*`}
          element={
            <AuthGuard>
              <ProjectPage />
            </AuthGuard>
          }
        />

        <Route
          path={`${routePath.settings}/*`}
          element={
            <AuthGuard>
              <SettingsPage />
            </AuthGuard>
          }
        />
        <Route
          path={routePath.manageSources}
          element={
            <AuthGuard>
              <SourcesPageReadOnlyContext.Provider value={true}>
                <SourcesPage />
              </SourcesPageReadOnlyContext.Provider>
            </AuthGuard>
          }
        />
        <Route
          path={routePath.dynamicReport}
          element={
            <AuthGuard>
              <DynamicFormPage />
            </AuthGuard>
          }
        />
        <Route
          path={`${routePath.dynamicReport}/:id`}
          element={
            <AuthGuard>
              <DynamicFormResultPage />
            </AuthGuard>
          }
        />
        <Route path={routePath.login} element={<LoginPage />} />
        <Route path={`${routePath.signup}/*`} element={<SignupPage />} />
        <Route path={routePath.logout} element={<LogoutPage />} />
        <Route path={routePath.forgotPassword} element={<ForgotPasswordPage />} />
      </Routes>
    </BrowserRouter>
  )
}
