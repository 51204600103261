import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import { Separator } from '@/components/ui/separator'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useToast } from '@/components/ui/use-toast'
import { useEnv } from '@/hooks/use-env'
import { isPromiseSettledFulfilled } from '@/lib/promise'
import { ApiDocumentUploadResponse, ApiSourceFile, Maybe } from '@/types'
import { DialogProps } from '@radix-ui/react-dialog'
import { Check, File, Loader2, Plus, Trash, XIcon } from 'lucide-react'
import { assoc, filter, forEach, gt, isEmpty, join, map, prop, reject, uniq } from 'ramda'
import { useContext, useEffect, useState } from 'react'
import useSWRMutation from 'swr/mutation'
import { v4 } from 'uuid'
import { AuthContext, AuthFunctionsContext } from './auth-provider'
import { captureException } from '@sentry/react'
import { allowedFileTypeList } from '@/features/chat/utils'

type Props = {
  children: React.ReactNode
  path: string
  sourceId: string
  onUploaded?: (items: ApiSourceFile[]) => void
} & DialogProps

type FileItem = {
  file: File
  id: string
}

type FileItemStatus = 'uploading' | 'uploaded' | 'error'

export function UploadSourcesDialog({ path, sourceId, children, ...props }: Props) {
  const auth = useContext(AuthContext)
  const authFunctions = useContext(AuthFunctionsContext)
  const toast = useToast()
  const [tabValue, setTabValue] = useState<TabValue>('b')
  const [fileList, setFileList] = useState<FileItem[]>([])
  const [fileStatusById, setFileStatusById] = useState<Record<string, FileItemStatus>>({})
  const { APP_API_BASE_URL } = useEnv()
  const uploadFetcher = useSWRMutation(`${APP_API_BASE_URL}/api/sources/${sourceId}/files`, uploadFileFetcher)

  useEffect(() => {
    if (!uploadFetcher.data || !props.onUploaded) {
      return
    }
    const uploadedDocumentList = uploadFetcher.data.filter(isPromiseSettledFulfilled).map((item) => item.value.document)

    props.onUploaded(uploadedDocumentList)
  }, [uploadFetcher.data])

  function handleFileInput(e: any) {
    const { files } = e.target
    addFiles(files)
  }

  function handleRemoveFileClick(value: FileItem) {
    if (fileStatusById[value.id] === 'uploading') {
      return
    }
    setFileList((prev) => reject<FileItem, FileItem[]>((item) => item.id === value.id, prev))
  }

  const notUploadedFileList = filter((item) => fileStatusById[item.id] !== 'uploaded', fileList)

  async function handleSubmit() {
    if (isEmpty(notUploadedFileList)) {
      toast.toast({ description: 'All files uploaded' })
      return
    }
    setFileListStatus('uploading', notUploadedFileList)
    uploadFetcher.trigger({ files: notUploadedFileList, path, authToken: auth?.session?.access_token })
  }

  function setFileListStatus(status: FileItemStatus, files: FileItem[]) {
    setFileStatusById((prev) => {
      let next = prev
      forEach<FileItem>((item) => {
        next = assoc(item.id, status, next)
      }, files)
      return next
    })
  }

  function setFileStatus(status: FileItemStatus, id: string) {
    setFileStatusById(assoc(id, status))
  }

  function handleFormDrop(e: React.DragEvent<HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()

    addFiles(Array.from(e.dataTransfer.files))
  }

  function addFiles(files: File[]) {
    setFileList((prev) => {
      const unsupportedFileTypeList = uniq(
        filter((item: string) => !allowedFileTypeList.includes(item), map(prop('type'), files))
      )
      const supportedFiles = filter((item) => allowedFileTypeList.includes(item.type), files)
      const supportedFileItems: FileItem[] = map((item) => ({ id: v4(), file: item }), supportedFiles)
      const nextList: FileItem[] = [...prev, ...supportedFileItems]

      if (unsupportedFileTypeList.length > 0) {
        toast.toast({
          description: `Files with following extentions not supported: ${unsupportedFileTypeList.join(' ,')}. `
        })
      }

      return nextList
    })
  }

  function handleFormDragOver(e: React.DragEvent<HTMLFormElement>) {
    e.preventDefault()
  }

  function handleFormDragEnter(e: React.DragEvent<HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()
  }

  const directoryInputProps = {
    webkitdirectory: '',
    directory: ''
  }

  const inputAcceptAttribute = join(',', allowedFileTypeList)

  const nextTab = getNextTab(tabValue)
  const prevTab = getPrevTab(tabValue)

  function handleTabChange(value: TabValue) {
    return () => setTabValue(value)
  }

  function renderFileIcon(file: FileItem) {
    const status = fileStatusById[file.id]
    switch (status) {
      case 'uploading':
        return <Loader2 size={16} className="animate-spin" />
      case 'uploaded':
        return <Check className="text-green-600" size={16} />
      case 'error':
        return <XIcon className="text-destructive" size={16} />
      default:
        return <Trash size={16} />
    }
  }

  function uploadFileFetcher(
    key: string,
    options: { arg: { files: FileItem[]; path: string; authToken?: Maybe<string> } }
  ) {
    return Promise.allSettled(
      options.arg.files.map((item): Promise<{ id: string; document: ApiSourceFile }> => {
        const body = new FormData()
        body.append('metadata', '')
        body.append('file', item.file)

        const searchParams = new URLSearchParams()
        searchParams.append('folder_path', options.arg.path)

        const headers = new Headers()

        if (options.arg.authToken) {
          headers.append('Authorization', `Bearer ${options.arg.authToken}`)
        }

        return fetch(`${key}?${searchParams.toString()}`, { method: 'POST', body, headers })
          .then((r) => r.json() as Promise<ApiDocumentUploadResponse>)
          .then((doc) => {
            setFileStatus('uploaded', item.id)
            return {
              id: item.id,
              document: doc
            }
          })
          .catch((e) => {
            if (e.status === 401) {
              authFunctions.setApiUnauthorized()
            } else {
              captureException(e)
              console.error(e)
            }
            setFileStatus('error', item.id)
            return e
          })
      })
    )
  }

  function handleOpenChanged() {
    clearState()
  }

  function clearState() {
    setFileList(() => [])
    setFileStatusById(() => ({}))
  }

  return (
    <Dialog onOpenChange={handleOpenChanged} {...props}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-w-[800px]">
        <DialogHeader>
          <DialogTitle className="mb-3">Upload Resources</DialogTitle>
        </DialogHeader>
        <Separator />

        <Tabs value={tabValue} className="h-[402px] flex justify-center items-center">
          <TabsList className="hidden">
            <TabsTrigger value="b">2</TabsTrigger>
          </TabsList>

          <TabsContent value="b" className="grow self-start h-full">
            <div className="flex flex-col h-full">
              <form
                className="border-dashed border p-2 flex justify-center items-center text-xs mb-2.5 h-20"
                onDrop={handleFormDrop}
                onDragOver={handleFormDragOver}
                onDragEnter={handleFormDragEnter}
              >
                <Plus size={16} className="text-primary" />
                <span className="text-gray-400">Drag & Drop or</span>
                &nbsp;
                <label htmlFor="upload-files-input" className="cursor-pointer underline text-primary">
                  Browse files
                </label>
                <input
                  name="files-input"
                  id="upload-files-input"
                  type="file"
                  hidden
                  multiple
                  value=""
                  accept={inputAcceptAttribute}
                  onInput={handleFileInput}
                />
                &nbsp; / &nbsp;
                <label htmlFor="upload-directory-input" className="cursor-pointer underline text-primary">
                  Browse folders
                </label>
                <input
                  name="directory-input"
                  id="upload-directory-input"
                  type="file"
                  hidden
                  multiple
                  value=""
                  accept={inputAcceptAttribute}
                  onInput={handleFileInput}
                  {...directoryInputProps}
                />
              </form>

              <div className="grow overflow-y-auto">
                {map(
                  (item) => (
                    <div key={item.id} className="flex justify-between items-center py-2.5">
                      <File size={22} className="mr-2" /> <span className="text-xs">{item.file.name}</span>{' '}
                      <div className="grow" />
                      <Button size="icon" variant="ghost" onClick={() => handleRemoveFileClick(item)}>
                        {renderFileIcon(item)}
                      </Button>
                    </div>
                  ),
                  fileList
                )}
              </div>
            </div>
          </TabsContent>
        </Tabs>

        <Separator />
        <DialogFooter>
          {prevTab && (
            <Button onClick={handleTabChange(prevTab)} variant="outline">
              Back
            </Button>
          )}
          {nextTab && <Button onClick={handleTabChange(nextTab)}>Next</Button>}
          {!nextTab && gt(fileList.length, 0) && isEmpty(notUploadedFileList) ? (
            <DialogClose asChild>
              <Button>Close</Button>
            </DialogClose>
          ) : (
            <Button onClick={handleSubmit} disabled={uploadFetcher.isMutating}>
              {uploadFetcher.isMutating && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              Upload
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

type TabValue = 'b'

function getNextTab(value: TabValue): Maybe<TabValue> {
  switch (value) {
    case 'b':
      return null
    default:
      return null
  }
}

function getPrevTab(value: TabValue): Maybe<TabValue> {
  switch (value) {
    default:
      return null
  }
}
