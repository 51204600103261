import {
  createDynamicReportFetcher,
  dataGroupsFetcher,
  dataSetFetcher,
  getDynamicReportListFetcher
} from '@/api/fetcher'
import { AuthContext } from '@/components/auth-provider'
import { PageLayout } from '@/components/page-layout'
import { PageLoader } from '@/components/page-loader'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Spinner } from '@/components/ui/spinner'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { useToast } from '@/components/ui/use-toast'
import { useBreakpoint } from '@/hooks/use-breakpoint'
import { useEnv } from '@/hooks/use-env'
import { formatDateAgo } from '@/lib/date'
import { routePath } from '@/router/route-path'
import { ApiDataSet, Maybe } from '@/types'
import { CheckedState } from '@radix-ui/react-checkbox'
import { captureException } from '@sentry/react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  RowSelectionState,
  Updater,
  useReactTable
} from '@tanstack/react-table'
import { parseISO } from 'date-fns'
import { find, head, isNil } from 'ramda'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'

export function DynamicFormPage() {
  const auth = useContext(AuthContext)
  const env = useEnv()
  const [dataGroupId, setDataGroupId] = useState<string | null>(null)
  const dataGroupApi = useSWR(
    [`${env.APP_API_BASE_URL}/api/data/groups`, auth?.session?.access_token],
    dataGroupsFetcher,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
      onSuccess: (data) => {
        setDataGroupId(head(data)?.id ?? null)
      }
    }
  )
  const dataSetsUrl = new URL(`${env.APP_API_BASE_URL}/api/data/sets`)
  if (dataGroupId) {
    dataSetsUrl.searchParams.set('data_group_id', dataGroupId)
  }
  const dataSetApi = useSWR(
    dataGroupId ? [dataSetsUrl.toString(), auth?.session?.access_token] : null,
    dataSetFetcher,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
      onSuccess: (data) => {
        setDataSetByGroup((prev) => {
          const item: Maybe<ApiDataSet> = head(data)
          if (!item) {
            return prev
          }
          prev.set(item.data_group_id, new Set(data))
          return new Map(prev)
        })
      }
    }
  )

  const [dataSetByGroup, setDataSetByGroup] = useState(new Map<string, Set<ApiDataSet>>())
  const [selectedDataSet, setSelectedDataSet] = useState(new Map<string, Set<string>>())

  const selectedDataSetCount: number = Array.from(selectedDataSet.values()).reduce((acum, item) => acum + item.size, 0)
  const navigate = useNavigate()
  const toast = useToast()

  useSWR([env.APP_API_BASE_URL, '/api/dynamic/reports', '', auth?.session?.access_token], getDynamicReportListFetcher, {
    revalidateOnFocus: false
  })

  const createDynamicReportApi = useSWRMutation('/api/dynamic/reports', createDynamicReportFetcher)

  function handleSubmit() {
    createDynamicReportApi.trigger(
      {
        baseUrl: env.APP_API_BASE_URL,
        dataSetIdList: Array.from(selectedDataSet.values()).flatMap((item) => Array.from(item))
      },
      {
        onSuccess(data) {
          if (isNil(data.id)) {
            toast.toast({
              variant: 'destructive',
              title: 'Failed to create report',
              description: 'Please try again.'
            })
            return
          }
          navigate(`${routePath.dynamicReport}/${encodeURIComponent(data.id)}`)
        },
        onError(error) {
          toast.toast({
            variant: 'destructive',
            title: 'Failed to create report',
            description: 'Please try again.'
          })
          captureException(error)
        }
      }
    )
  }

  function handleCheckedChange(value: CheckedState, dataSetItem: ApiDataSet) {
    setSelectedDataSet((prev) => {
      if (!dataGroupId) {
        return prev
      }
      if (!selectedDataSet.has(dataGroupId)) {
        selectedDataSet.set(dataGroupId, new Set())
      }

      prev.forEach((item) => item.clear())

      if (value) {
        prev.get(dataGroupId)?.add(dataSetItem.id)
      } else {
        prev.get(dataGroupId)?.delete(dataSetItem.id)
      }
      return new Map(prev)
    })
  }

  if (dataGroupApi.error) {
    return (
      <PageLayout>
        <div className="flex bg-gray-400 h-full">
          <div className="grid grid-cols-6 grow overflow-y-auto">
            <div className="col-span-2 bg-orange-200">Failed to load data. Please try again.</div>
          </div>
        </div>
      </PageLayout>
    )
  }

  if (dataGroupApi.isLoading || createDynamicReportApi.isMutating) {
    return (
      <PageLayout>
        <PageLoader />
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      <div className="flex items-center gap-2 mb-2">
        <div>Sources</div>
        <div className="grow" />
        <Button onClick={handleSubmit} disabled={selectedDataSetCount === 0 || createDynamicReportApi.isMutating}>
          {createDynamicReportApi.isMutating ? <Spinner /> : 'Submit'}
        </Button>
      </div>
      <div className="flex h-full">
        <div className="bg-white grow overflow-y-auto gap-4 rounded p-4">
          <Accordion
            type="single"
            value={dataGroupId ?? ''}
            onValueChange={(event: string) => {
              setDataGroupId(event)
            }}
          >
            {dataGroupApi.data?.map((dataGroupItem) => {
              // TODO: keep for later
              // const currentSelectedDataSet = selectedDataSet.get(dataGroupItem.id) ?? new Set<string>()
              // const selectedCount = currentSelectedDataSet.size
              // const selectedCountText = selectedCount > 0 ? `(${selectedCount})` : ''
              return (
                <AccordionItem key={dataGroupItem.id} value={dataGroupItem.id}>
                  <AccordionTrigger>{dataGroupItem.name}</AccordionTrigger>
                  <AccordionContent>
                    {dataSetApi.isLoading && <div>Loading...</div>}
                    {dataSetApi.error && <div>Failed to load data. Please try again.</div>}
                    {dataSetApi.data?.length === 0 && <div>No data sets found.</div>}

                    <DataSetList
                      value={Array.from(dataSetByGroup.get(dataGroupItem.id) ?? [])}
                      activeDataSet={dataGroupId ? selectedDataSet.get(dataGroupId) : new Set()}
                      onCheckedChange={handleCheckedChange}
                    />
                  </AccordionContent>
                </AccordionItem>
              )
            })}
          </Accordion>
        </div>
      </div>
    </PageLayout>
  )
}

function DataSetList({
  value,
  activeDataSet,
  onCheckedChange
}: {
  value: ApiDataSet[]
  activeDataSet: Maybe<Set<string>>
  onCheckedChange: (value: CheckedState, dataSetItem: ApiDataSet) => void
}) {
  const { isMd } = useBreakpoint()
  // const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const rowSelection: RowSelectionState = Object.fromEntries(
    value
      .map((item) => [item.id, activeDataSet?.has(item.id) ? true : false])
      .filter((item): item is [string, boolean] => !!item[1])
  )

  const columns: ColumnDef<ApiDataSet>[] = isMd
    ? [
        {
          accessorKey: 'select-col',
          size: 10,
          maxSize: 10,
          header() {
            return ''
          },
          cell({ row }) {
            return (
              <div className="px-4">
                <Checkbox
                  checked={row.getIsSelected()}
                  disabled={!row.getCanSelect()}
                  onCheckedChange={row.getToggleSelectedHandler()}
                />
              </div>
            )
          }
        },
        {
          accessorKey: 'name',
          header() {
            return 'Name'
          },
          cell({ row }) {
            return row.getValue('name')
          }
        },
        {
          accessorKey: 'row_count',
          header() {
            return <div className="text-right">Row Count</div>
          },
          cell({ row: { getValue } }) {
            return <div className="text-right">{Intl.NumberFormat().format(getValue('row_count'))}</div>
          }
        },
        {
          accessorKey: 'last_altered',
          header() {
            return 'Last Updated'
          },
          cell({ row }) {
            const parsed: Date = row.getValue('last_altered')
            return formatDateAgo(parsed, new Date())
          }
        }
      ]
    : [
        {
          accessorKey: 'select-col',
          size: 10,
          maxSize: 10,
          header() {
            return ''
          },
          cell({ row }) {
            return (
              <div className="px-4">
                <Checkbox
                  checked={row.getIsSelected()}
                  disabled={!row.getCanSelect()}
                  onCheckedChange={row.getToggleSelectedHandler()}
                />
              </div>
            )
          }
        },
        {
          accessorKey: 'name',
          header() {
            return ''
          },
          cell({ row }) {
            return (
              <div className="flex flex-col">
                <div className="font-semibold">{row.getValue('name')}</div>
                <div className="flex justify-between">
                  <div>
                    Row Count: {row.original.row_count ? Intl.NumberFormat().format(row.original.row_count) : 'N/A'}
                  </div>
                  <div>
                    Last Updated:{' '}
                    <span className="whitespace-nowrap">
                      {row.original.last_altered
                        ? formatDateAgo(parseISO(row.original.last_altered), new Date())
                        : 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
            )
          }
        }
      ]

  const table = useReactTable({
    data: value,
    columns,
    enableRowSelection: !isNil(rowSelection),
    getCoreRowModel: getCoreRowModel(),
    getRowId(originalRow) {
      return originalRow.id
    },
    state: {
      rowSelection //pass the row selection state back to the table instance
    },
    onRowSelectionChange: (updater: Updater<RowSelectionState>) => {
      if (typeof updater !== 'function') {
        return
      }

      const prev = rowSelection
      const next = updater({})

      const checked = Object.keys(next).length > 0
      let id = Object.keys(prev)[0]

      if (Object.keys(next)[0]) {
        id = Object.keys(next)[0]
      }

      const item = find((item) => item.id === id, value)

      if (item) {
        onCheckedChange(checked, item)
      }
    }
  })

  return (
    <Table>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead key={header.id}>
                  {!header.isPlaceholder && flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              )
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow
            key={row.id}
            onClick={() => {
              row.toggleSelected()
            }}
          >
            {row.getVisibleCells().map((cell) => {
              return (
                <TableCell className="px-0 md:px-4" key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              )
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )

  // const rawList = value.map((dataSetItem) => {
  //   const checked = !!activeDataSet?.has(dataSetItem.id)
  //   return (
  //     <Fragment key={dataSetItem.id}>
  //       <Checkbox
  //         id={dataSetItem.id}
  //         checked={checked}
  //         onCheckedChange={(value) => onCheckedChange(value, dataSetItem)}
  //       />
  //       <label htmlFor={dataSetItem.id}>{dataSetItem.name}</label>
  //       <div>{!isNil(dataSetItem.row_count) && Intl.NumberFormat().format(dataSetItem.row_count)}</div>
  //       <div>{!isNil(dataSetItem.last_altered) && formatDateAgo(parseISO(dataSetItem.last_altered), new Date())}</div>
  //     </Fragment>
  //   )
  // })

  // return (
  //   <div className="grid md:grid-cols-[min-content_auto_max-content_max-content] gap-4">
  //     <div />
  //     <div>Name</div>
  //     <div>Row Count</div>
  //     <div>Last Updated</div>
  //     {rawList}
  //   </div>
  // )
}
