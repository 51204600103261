import { ApiProjectMessage, MessageView } from '@/types'
import { format, parseISO } from 'date-fns'
import { isEmpty } from 'ramda'
import { v4 } from 'uuid'
import { Result, result } from '../result'

export function mapMessageToView(message: ApiProjectMessage, files: File[] = []): MessageView {
  const createdDate = message.created_at ? parseISO(`${message.created_at}Z`) : new Date()
  return {
    id: message.id,
    type: message.role,
    content: message.content,
    date: createdDate,
    loading: isEmpty(message.content),
    status: message.status,
    researchPlanId: message.meta_data?.research_plan_id,
    projectId: message.project_id,
    subProcessList: [],
    files
  }
}

export function mapTextToMessageView(text: string, files: File[]) {
  const message: ApiProjectMessage = {
    id: v4(),
    project_id: '',
    content: text,
    role: 'user',
    status: 'SUCCESS'
  }
  const messageView = mapMessageToView(message, files)
  messageView.type = 'user_input'
  return messageView
}

export function formatMessageDate(date: Date): string {
  return format(date, 'EEEE, MMMM d, hh:mm a')
}

export function mapMessageEvent<T>(e: MessageEvent): Result<T> {
  try {
    const jsonString = (e.data ?? '').replace('data: ', '')
    const mapped = JSON.parse(jsonString) as T
    return result.ok(mapped)
  } catch (e) {
    return result.failed(e as Error)
  }
}
