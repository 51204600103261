import { getFileDownloadUrlFetcher } from '@/api/fetcher'
import { AuthContext } from '@/components/auth-provider'
import { useEnv } from '@/hooks/use-env'
import { copyToClipboard } from '@/lib/copy-to-clipboard'
import { toHtmlId } from '@/lib/dom'
import { createMarkdownRenderer } from '@/lib/markdown'
import { Maybe, ResearchReport, ResearchReportSource } from '@/types'
import { Copy } from 'lucide-react'
import { isEmpty } from 'ramda'
import { useCallback, useContext, useRef } from 'react'
import { AttachmentList } from './attachment-list'
import { SourceList } from './source-list'
import { Button } from './ui/button'
import { Separator } from './ui/separator'
import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip'
import { useToast } from './ui/use-toast'

type ResearchReportItemProps = {
  report?: Maybe<ResearchReport>
}

export function ResearchReportItem({ report }: ResearchReportItemProps) {
  const { toast } = useToast()
  const ref = useRef<HTMLDivElement | null>(null)
  const env = useEnv()
  const auth = useContext(AuthContext)

  if (!report) {
    return null
  }

  async function handleCopy() {
    if (!ref.current) {
      return
    }
    try {
      await copyToClipboard(ref.current)
      toast({ description: 'Copied successfully' })
    } catch (e) {
      toast({ variant: 'destructive', description: 'Failed to copy the report' })
    }
  }

  const rendererRef = useRef(createMarkdownRenderer())

  const handleSourceClick = useCallback(
    async (url: Maybe<string>) => {
      if (!url) {
        return
      }
      if (url.startsWith('/')) {
        const downloadUrl = await getFileDownloadUrlFetcher('', {
          arg: {
            baseUrl: env.APP_API_BASE_URL,
            path: url,
            authToken: auth?.session?.access_token
          }
        })
        window.open(downloadUrl, '_blank', 'noopener,noreferrer')
      } else {
        window.open(url, '_blank', 'noopener,noreferrer')
      }
    },
    [env.APP_API_BASE_URL, auth?.session?.access_token]
  )

  const replaceCitationMarkers = useCallback((content: string, sources: ResearchReportSource[]) => {
    return content.replace(/\[(\d+)\]/g, (match, p1) => {
      const sourceIndex = parseInt(p1) - 1
      const source = sources[sourceIndex]
      if (source && source.url) {
        return `<a href="#" data-source-url="${source.url}" style="color: rgb(59 130 246 / var(--tw-text-opacity, 1));">${match}</a>`
      }
      return match
    })
  }, [])

  const renderedContent = rendererRef.current.render(replaceCitationMarkers(report.content, report.sources || []))

  const handleContentClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const target = event.target as HTMLElement
      if (target.tagName === 'A' && target.hasAttribute('data-source-url')) {
        event.preventDefault()
        const url = target.getAttribute('data-source-url')
        handleSourceClick(url)
      }
    },
    [handleSourceClick]
  )

  return (
    <div className="border rounded mb-4 p-2" ref={ref} id={toHtmlId(report.research_plan_id)}>
      <div className="font-normal pb-2 flex">
        <div className="flex-grow">{report.title}</div>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button className="shrink-0" variant="ghost" size="icon" onClick={handleCopy}>
              <Copy size={16} />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Copy to clipboard</TooltipContent>
        </Tooltip>
      </div>
      <div className="text-sm" style={{ whiteSpace: 'pre-wrap' }}>
        <div
          className="[&_ul]:list-disc [&_ul]:pl-6 [&_ol]:list-decimal [&_ol]:pl-6"
          dangerouslySetInnerHTML={{ __html: renderedContent }}
          onClick={handleContentClick}
        />
      </div>
      <AttachmentList attachments={report.attachments} />
      {!isEmpty(report.sources) && <Separator className="w-60 my-4" />}
      <SourceList sources={report.sources} />
    </div>
  )
}
