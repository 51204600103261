import { DocumentSource, DocumentSourceSelectionFile } from '@/types'
import { CheckedState } from '@radix-ui/react-checkbox'
import { Checkbox } from './ui/checkbox'
import { FileText, MoreVertical, Trash, ArrowDownToLine, Pointer } from 'lucide-react'
import { formatDateAgo } from '@/lib/date'
import { parseISO } from 'date-fns'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from './ui/dropdown-menu'
import { Button } from './ui/button'
import { cn } from '@/lib/utils'
import { useEnv } from '@/hooks/use-env'
import { AuthContext } from '@/components/auth-provider'
import { useContext, useState } from 'react'
import { getFileDownloadUrlFetcher } from '@/api/fetcher'
import { match } from 'ts-pattern'
import { always, F } from 'ramda'
import { SourceType } from '@/api/octagon-api-generated'

type DocumentFileItemProps = {
  item: DocumentSourceSelectionFile
  source: DocumentSource
  showOnly: boolean
  readOnly: boolean
  onCheckedChange(item: DocumentSourceSelectionFile, value: CheckedState): void
  onDelete(item: DocumentSourceSelectionFile): void
  onOnlyClick(item: DocumentSourceSelectionFile): void
}

export function DocumentFileItem({
  item,
  source,
  showOnly,
  readOnly,
  onCheckedChange,
  onDelete,
  onOnlyClick
}: DocumentFileItemProps) {
  const env = useEnv()
  const auth = useContext(AuthContext)
  const [open, setOpen] = useState(false)

  const handleDownload = async (url: string) => {
    const downloadUrl = await getFileDownloadUrlFetcher('', {
      arg: {
        baseUrl: env.APP_API_BASE_URL,
        path: url,
        authToken: auth?.session?.access_token
      }
    })
    window.open(downloadUrl, '_blank', 'noopener,noreferrer')
  }

  return (
    <div className="flex items-center group md:hover:bg-secondary min-h-10">
      {!readOnly && (
        <Checkbox
          id={item.id}
          name={item.title}
          className="mr-2"
          checked={item.selected}
          onCheckedChange={(value) => onCheckedChange(item, value)}
        />
      )}
      <label className="text-sm flex flex-nowrap items-center" htmlFor={item.id}>
        <FileText size={16} className="mr-1 flex-shrink-0" />
        {item.title}
      </label>

      <div className="flex-grow" />

      {!readOnly && showOnly && (
        <Button
          size="sm"
          onClick={(e) => {
            e.stopPropagation()
            onOnlyClick(item)
          }}
          className={cn('h-6 border border-primary bg-transparent mr-4 font-normal hidden md:group-hover:block')}
          variant="outline"
        >
          Only
        </Button>
      )}

      <div className="whitespace-nowrap text-xs text-purple">
        {formatDateAgo(item.createdAt ? parseISO(`${item.createdAt}Z`) : new Date(), new Date())}
      </div>

      {match(source.type)
        .with(SourceType.Public, SourceType.Sql, SourceType.Uploads, () => (
          <DropdownMenu open={open} onOpenChange={setOpen}>
            <DropdownMenuTrigger asChild>
              <Button size="icon" variant="ghost" className="flex-shrink-0" onClick={(e) => e.stopPropagation()}>
                <MoreVertical size={16} className="text-purple" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuGroup className="space-y-1">
                <DropdownMenuItem
                  className="space-x-2"
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    setOpen(F)
                    handleDownload(item.path)
                  }}
                >
                  <ArrowDownToLine size={16} /> <span>Download</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="space-x-2"
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    setOpen(F)
                    onOnlyClick(item)
                  }}
                >
                  <Pointer size={16} /> <span>Only</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="space-x-2"
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    setOpen(F)
                    onDelete(item)
                  }}
                >
                  <Trash size={16} className="text-destructive" /> <span>Delete</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        ))
        .with(SourceType.Drive, always(null))
        .exhaustive()}
    </div>
  )
}
