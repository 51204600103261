import katex from 'katex'
import md from 'markdown-it'
import tm from 'markdown-it-texmath'

export function createMarkdownRenderer() {
  return md({ html: true }).use(tm, {
    engine: katex,
    delimiters: 'brackets'
  })
}
