import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { Form, FormField, FormItem, FormMessage } from '@/components/ui/form'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { useMount } from '@/hooks/use-mount'
import { cn } from '@/lib/utils'
import { Agent } from '@/types'
import { zodResolver } from '@hookform/resolvers/zod'
import Cookies from 'js-cookie'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { match } from 'ts-pattern'
import { z } from 'zod'
import { AgentValue, OutputValue } from '../types'
import { PromptBuilderSecFilingsForm, SecFilingsFormValue, secFilingsSchema } from './prompt-builder-sec-filings-form'
import { PromptBuilderWebCrawlerForm, WebCrawlerFormValue, webCrawlerSchema } from './prompt-builder-web-crawler-form'
import {
  PromptBuilderTranscriptsForm,
  TranscriptsFormValue,
  transcriptsSchema
} from './prompt-builder-transcripts-form'
import { prop, sortBy } from 'ramda'

const schema = z.object({
  agent: z.nativeEnum(AgentValue)
})

const agentOptionList: { value: AgentValue; label: string }[] = sortBy(prop('label'), [
  {
    value: AgentValue.WebCrawler,
    label: AgentValue.WebCrawler
  },
  {
    value: AgentValue.SecFilings,
    label: AgentValue.SecFilings
  },
  {
    value: AgentValue.EarningsCallTranscripts,
    label: AgentValue.EarningsCallTranscripts
  }
])

export type FormValue = z.infer<typeof schema>

const Steps = { one: 'one', two: 'two' }

type Props = {
  id?: string
  onSubmit: (prompt: string) => void
}

const PROMPT_BUILDER_AGENT_COOKIE_NAME = 'promptBuilderAgent'

export function PromptBuilderForm({ id, onSubmit }: Props) {
  const [step, setStep] = useState([Steps.one])

  const form = useForm<FormValue>({
    resolver: zodResolver(schema)
  })

  const webCrawlerForm = useForm<WebCrawlerFormValue>({
    resolver: zodResolver(webCrawlerSchema),
    defaultValues: {
      url: '',
      maxPages: '1',
      allFieldsCheckbox: true,
      outputs: [OutputValue.JSON, OutputValue.CSV]
    }
  })

  const secFilingsForm = useForm<SecFilingsFormValue>({
    resolver: zodResolver(secFilingsSchema),
    defaultValues: {
      targetCompany: '',
      timePeriod: '',
      filingType: [],
      targetMetric: '',
      outputs: [OutputValue.JSON, OutputValue.CSV]
    }
  })

  const transcriptsForm = useForm<TranscriptsFormValue>({
    resolver: zodResolver(transcriptsSchema)
  })

  useMount(() => {
    const promptBuilderAgent = Cookies.get(PROMPT_BUILDER_AGENT_COOKIE_NAME)
    if (promptBuilderAgent) {
      const agent = mapStringToAgentValue(promptBuilderAgent)
      if (agent) {
        handleAgentChange(agent)
      }
      Cookies.remove(PROMPT_BUILDER_AGENT_COOKIE_NAME)
    }
  })

  const webCrawlerSubmit = webCrawlerForm.handleSubmit(handleWebCrawlerSubmit)

  const secFilingsSubmit = secFilingsForm.handleSubmit(handleSecFilingsSubmit)

  const transcriptsSubmit = transcriptsForm.handleSubmit(handleTranscriptsSubmit)

  function mapStringToAgentValue(value: string): AgentValue | undefined {
    return match<string, AgentValue | undefined>(value)
      .with(Agent.CrawlerAgent, () => AgentValue.WebCrawler)
      .with(Agent.SECFilingsAgent, () => AgentValue.SecFilings)
      .otherwise(() => undefined)
  }

  function handleAgentChange(value: AgentValue) {
    form.setValue('agent', value)
    setStep([Steps.one, Steps.two])
  }

  async function handleSubmit(value: FormValue) {
    await match(value.agent)
      .with(AgentValue.WebCrawler, async () => {
        await webCrawlerSubmit()
      })
      .with(AgentValue.SecFilings, async () => {
        await secFilingsSubmit()
      })
      .with(AgentValue.EarningsCallTranscripts, async () => {
        await transcriptsSubmit()
      })
      .exhaustive()
  }

  function handleWebCrawlerSubmit(value: WebCrawlerFormValue) {
    const prompt = mapWebCrawlerFormToPrompt(value)
    onSubmit(prompt)
  }

  function handleSecFilingsSubmit(value: SecFilingsFormValue) {
    const prompt = mapSecFilingsFormToPrompt(value)
    onSubmit(prompt)
  }

  function handleTranscriptsSubmit(value: TranscriptsFormValue) {
    const prompt = mapTranscriptsFormToPrompt(value)
    onSubmit(prompt)
  }

  function mapWebCrawlerFormToPrompt(value: WebCrawlerFormValue): string {
    const fields = value
    const parts = [
      [`@${AgentValue.WebCrawler}`, `Crawl from ${fields.url}.`].join(' '),
      fields.allFieldsCheckbox ? 'Extract all fields.' : '',
      fields.detailedPagesCheckbox ? 'Include detailed pages.' : 'Do not include detailed pages.',
      `max_pages:${fields.maxPages}.`,
      `analytics:${fields.outputs?.includes(OutputValue.Analytics) ? 'true' : 'false'}.`,
      `${fields.outputs?.includes(OutputValue.PythonScript) ? 'script:true' : ''}.`,
      fields.outputs && fields.outputs.length > 0 ? `Include ${fields.outputs.join(', ')}.` : ''
    ]
    return parts.join(' ')
  }

  function mapSecFilingsFormToPrompt(value: SecFilingsFormValue): string {
    const fields = value
    const parts = [
      [
        `@${AgentValue.SecFilings}`,
        `Retrieve the ${fields.filingType.join(', ')} filings for ${fields.targetCompany} for the period ${
          fields.timePeriod
        }.`
      ].join(' '),
      `Extract the ${fields.targetMetric}.`,
      fields.outputs && fields.outputs.length > 0 ? `Generate results in ${fields.outputs.join(', ')}.` : '',
      fields.outputs.includes(OutputValue.Analytics) ? 'Perform descriptive analytics with the extracted data set.' : ''
    ]
    return parts.join(' ')
  }

  function mapTranscriptsFormToPrompt(value: TranscriptsFormValue): string {
    const fields = value
    const parts = [
      [
        `@${AgentValue.EarningsCallTranscripts}`,
        `Retrieve the transcripts for ${fields.targetCompany} for the period ${fields.timePeriod}.`
      ].join(' '),
      `${fields.targetMetric}.`,
      fields.outputs && fields.outputs.length > 0 ? `Structure results in ${fields.outputs.join(', ')}.` : '',
      fields.outputs?.includes(OutputValue.Analytics)
        ? 'Perform descriptive analytics with the extracted data set.'
        : ''
    ]
    return parts.join(' ')
  }

  return (
    <Accordion type="multiple" value={step} className="overflow-y-auto" data-testid="prompt-builder-accordion">
      <AccordionItem value={Steps.one} className="border-b-0 mb-4">
        <AccordionTrigger
          className={cn('font-semibold text-sm hover:no-underline text-muted-foreground')}
          hideChevron
          data-testid="prompt-builder-step-1-trigger"
        >
          Step 1 - Select task agent
        </AccordionTrigger>
        <AccordionContent className="p-1">
          <Form {...form}>
            <form id={id} onSubmit={form.handleSubmit(handleSubmit)}>
              <FormField
                control={form.control}
                name="agent"
                render={({ field }) => (
                  <FormItem>
                    <Select
                      value={field.value}
                      onValueChange={(e) => {
                        handleAgentChange(e as AgentValue)
                      }}
                    >
                      <SelectTrigger autoFocus data-testid="prompt-builder-step-1-select-trigger">
                        <SelectValue placeholder="Not selected" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {agentOptionList.map((item) => (
                            <SelectItem
                              key={item.value}
                              value={item.value}
                              data-testid="prompt-builder-step-1-select-item"
                            >
                              {item.label}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </AccordionContent>
      </AccordionItem>

      <AccordionItem value={Steps.two} className="border-b-0">
        <AccordionTrigger
          className={cn(
            'font-normal text-sm hover:no-underline text-muted-foreground',
            step.includes(Steps.two) && 'font-semibold'
          )}
          hideChevron
        >
          Step 2 - Specify additional options
        </AccordionTrigger>
        <AccordionContent className="p-1">
          {match<AgentValue | undefined>(form.getValues().agent)
            .with(AgentValue.WebCrawler, () => {
              return <PromptBuilderWebCrawlerForm form={webCrawlerForm} onSubmit={webCrawlerSubmit} />
            })
            .with(AgentValue.SecFilings, () => {
              return <PromptBuilderSecFilingsForm form={secFilingsForm} onSubmit={secFilingsSubmit} />
            })
            .with(AgentValue.EarningsCallTranscripts, () => {
              return <PromptBuilderTranscriptsForm form={transcriptsForm} onSubmit={transcriptsSubmit} />
            })
            .with(undefined, () => {
              return null
            })
            .exhaustive()}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}
