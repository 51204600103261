import { Maybe } from '@/types'

type ApiCallOptions = {
  url: string
  search?: URLSearchParams
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
  token?: Maybe<string>
  body?: string | FormData
  contentType?: string
  isJson?: boolean
}

export function apiCall<T>(options: ApiCallOptions): Promise<T> {
  const { url, search = new URLSearchParams(), method = 'GET', token, body, contentType, isJson = true } = options

  const headers = new Headers({
    'Content-Type': contentType ?? 'application/json'
  })

  if (token) {
    headers.append('Authorization', `Bearer ${token}`)
  }

  // Read all cookies and add them as custom headers
  document.cookie.split(';').forEach((cookie) => {
    const [key, value] = cookie.trim().split('=')
    headers.append(`X-Cookie-${key.toUpperCase()}`, value)
  })

  return fetch(search.size === 0 ? url : `${url}?${search.toString()}`, {
    method,
    headers,
    body
  }).then((r) => {
    if (!r.ok) {
      throw {
        status: r.status,
        statusText: r.statusText
      }
    }
    if (!isJson) {
      return r.arrayBuffer()
    }
    return r.json()
  })
}

export type WithAuthToken = {
  authToken?: Maybe<string>
}
