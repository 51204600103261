import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'

import '@fontsource/poppins'
import '@fontsource/poppins/100.css'
import '@fontsource/poppins/200.css'
import '@fontsource/poppins/300.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'
import '@fontsource/poppins/800.css'

import './sentry.ts'
// import { pdfjs } from 'react-pdf'

// pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString()

ReactDOM.createRoot(document.getElementById('root')!).render(
  // disabled strict mode because on /create-project page when page mounted twice
  // and calls useEffect twice
  // two projects created instead of one

  // <React.StrictMode>
  <App />
  // </React.StrictMode>,
)
