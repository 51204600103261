import { PromptFunctionsContext } from '@/contexts/prompt'
import { isEnterHotkey } from '@/lib/hotkey'
import { allowedAgentList, createAgentSuggestion } from '@/lib/tiptap/agent-suggestion'
import { MentionTicker } from '@/lib/tiptap/ticker-mentions'
import { createTickerSuggestion } from '@/lib/tiptap/ticker-suggestion'
import { Maybe, Project } from '@/types'
import Mention from '@tiptap/extension-mention'
import Placeholder from '@tiptap/extension-placeholder'
import { EditorContent, useEditor } from '@tiptap/react'
import { StarterKit } from '@tiptap/starter-kit'
import Cookies from 'js-cookie'
import { File, Paperclip, Plus, SendHorizonal, Trash } from 'lucide-react'
import { KeyboardEventHandler, useContext, useEffect, useRef, useState } from 'react'
import { Button } from './ui/button'
import { Card, CardContent } from './ui/card'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from './ui/dropdown-menu'
import { allowedPromptFileTypeList } from '@/features/chat/utils'
import { filter, join, map, prop, uniq } from 'ramda'
import { v4 } from 'uuid'
import { useToast } from './ui/use-toast'

type Props = {
  overrideValue?: string
  project?: Maybe<Project>
  onSubmit(value: { prompt: string; files: File[] }): void
}

type FileItem = {
  file: File
  id: string
}

export function PromptInput({ project, overrideValue, onSubmit }: Props) {
  const promptFunctions = useContext(PromptFunctionsContext)

  const editor = useEditor({
    extensions: [
      StarterKit,
      Mention.configure({
        HTMLAttributes: {
          class: 'mention'
        },
        suggestion: createAgentSuggestion()
      }),
      MentionTicker.configure({
        HTMLAttributes: {
          class: 'mention-ticker'
        },
        suggestion: createTickerSuggestion()
      }),
      Placeholder.configure({
        placeholder: 'Enter Research Request…'
      })
    ],
    editorProps: {
      attributes: {
        class: 'w-full pr-10 border-none max-h-32 h-fit min-h-10 outline-none overflow-y-auto p-2'
      },
      handleKeyDown: (_, event: KeyboardEvent) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault()
          handleSubmit()
          return true // Indicate that the event is handled
        }
        return false // Indicate that the event should be handled by the editor
      }
    },
    content: `<p></p>`,
    onUpdate({ editor }) {
      promptFunctions(project?.id, editor.getText())
    }
  })

  useEffect(() => {
    if (editor) {
      const ctaPrompt = Cookies.get('ctaPrompt')
      if (ctaPrompt) {
        editor.commands.setContent(`<p>${ctaPrompt}</p>`)
        promptFunctions(project?.id, ctaPrompt)
        Cookies.remove('ctaPrompt')
      } else if (overrideValue) {
        editor.commands.setContent(`<p>${overrideValue}</p>`)
        promptFunctions(project?.id, overrideValue)
      }
    }
  }, [editor, overrideValue, project?.id, promptFunctions])

  useEffect(() => {
    editor?.commands.setContent(`<p>${overrideValue}</p>`)
    if (overrideValue) {
      promptFunctions(project?.id, overrideValue)
    }
  }, [overrideValue])

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (isEnterHotkey(e)) {
      e.preventDefault()
      handleSubmit()
    }
  }

  function handleSubmit() {
    const editorText = editor?.getText().trim()
    if (!editorText) {
      return
    }
    onSubmit({ prompt: editorText, files: fileList.map((item) => item.file) })
    editor?.commands.clearContent()
    setFileList([])
  }

  const inputRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'inherit'
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`
    }
  }, [editor?.getText()])

  const toast = useToast()

  const inputAcceptAttribute = join(',', allowedPromptFileTypeList)

  const [fileList, setFileList] = useState<FileItem[]>([])

  function handleFileInput(e: any) {
    const { files } = e.target
    addFiles(files)
  }

  function addFiles(files: File[]) {
    setFileList((prev) => {
      const unsupportedFileTypeList = uniq(
        filter((item: string) => !allowedPromptFileTypeList.includes(item), map(prop('type'), files))
      )
      const supportedFiles = filter((item) => allowedPromptFileTypeList.includes(item.type), files)
      const supportedFileItems: FileItem[] = map((item) => ({ id: v4(), file: item }), supportedFiles)
      const nextList: FileItem[] = [...prev, ...supportedFileItems]

      if (unsupportedFileTypeList.length > 0) {
        toast.toast({
          description: `Files with following extentions not supported: ${unsupportedFileTypeList.join(' ,')}. `
        })
      }

      return nextList
    })
  }

  function handleRemoveFileClick(item: FileItem) {
    setFileList((prev) => prev.filter((file) => file.id !== item.id))
  }

  return (
    <div>
      <Card className="mb-2">
        <CardContent className="p-1 pb-1">
          <div className="relative">
            <EditorContent
              data-testid="input-prompt"
              className=""
              ref={inputRef}
              onKeyDown={handleKeyDown}
              editor={editor}
              style={{ outline: 'none' }}
            />

            <Button
              data-testid="btn-submit-prompt"
              variant="ghost"
              className="absolute right-0 top-0 hover:bg-transparent"
              style={{ marginTop: 0 }}
              onClick={() => handleSubmit()}
            >
              <SendHorizonal size="16" />
            </Button>
          </div>

          {fileList.length > 0 && (
            <div className="overflow-y-auto max-h-32 pl-2">
              <div className="text-xs text-muted-foreground">Attached Files ({fileList.length})</div>
              {map(
                (item) => (
                  <div key={item.id} className="flex items-center py-1">
                    <Button
                      size="icon"
                      className="p-0 size-[16px] mr-2"
                      variant="ghost"
                      onClick={() => handleRemoveFileClick(item)}
                    >
                      <Trash size={14} className="text-red-500" />
                    </Button>
                    <File size={16} className="mr-2" />
                    <span className="text-xs">{item.file.name}</span>
                  </div>
                ),
                fileList
              )}
            </div>
          )}

          {/* toolbar */}
          <div className="flex gap-1">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon">
                  <Plus size={16} />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start">
                {allowedAgentList.sort().map((item) => (
                  <DropdownMenuItem
                    key={item}
                    onClick={() => {
                      editor?.commands.insertContent(`@${item}`)
                      editor?.commands.insertContent(` `)
                    }}
                  >
                    {item}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>

            <Button variant="ghost" size="icon">
              <label htmlFor="upload-files-input" className="cursor-pointer underline text-primary">
                <Paperclip size={16} className="-rotate-45" />
              </label>
              <input
                name="files-input"
                id="upload-files-input"
                type="file"
                hidden
                multiple
                value=""
                accept={inputAcceptAttribute}
                onInput={handleFileInput}
              />
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
