import { cn } from '@/lib/utils'
import ReactMarkdown, { Options as MdOptions } from 'react-markdown'

export function Markdown(props: MdOptions) {
  const classList = ['[&_ol]:list-decimal', '[&_ol]:pl-6', '[&_h3]:font-semibold', '[&_a]:text-blue-500']
  return (
    <ReactMarkdown
      components={{
        a: (props) => <a {...props} target="_blank" rel="noopener noreferrer" />,
        code: (props) => <code {...props} style={{ whiteSpace: 'pre-wrap', ...props.style }} />,
        pre: (props) => <pre {...props} style={{ whiteSpace: 'pre-wrap', ...props.style }} />
      }}
      {...props}
      className={cn(props.className, ...classList)}
    />
  )
}
