import { CheckedState } from '@radix-ui/react-checkbox'
import { DataSetResponse, SourceType } from './api/octagon-api-generated'

export type Project = {
  id: string
  title: Maybe<string>
  createdAt: Date
  updatedAt: Date
  researchPlanStatus: Maybe<ResearchPlanStatus>
}

export type Owner = {
  name: string
  email: string
  avatar?: string
}

export type ProjectById = Record<string, Project>

export type ApiDocument = {
  id?: string
  created_at?: string
  updated_at?: string
  url: string
  metadata_map?: Partial<Record<DocumentType, DocumentMetadata>>
}

export type SubQuestion = {
  answer?: string
  question?: string
  citations?: Citation[]
}

export type Citation = {
  document_id: string
  page_number: number
  score: number
  text: string
}

export type ConversationMessageSubProcess = {
  created_at: string
  id: string
  message_id: string
  metadata_map?: {
    sub_question?: SubQuestion
    sub_questions?: SubQuestion[]
  }
  source:
    | 'chunking'
    | 'node_parsing'
    | 'embedding'
    | 'llm'
    | 'query'
    | 'retrieve'
    | 'synthesize'
    | 'tree'
    | 'sub_question'
    | 'templating'
    | 'function_call'
    | 'reranking'
    | 'exception'
    | 'agent_step'
    | 'constructed_query_engine'
    | 'sub_questions'
  status: 'PENDING' | 'FINISHED'
  updated_at: string
}

export type CitationView = {
  documentId: string
  snippet: string
  pageNumber: number
  ticker: string
  displayDate: string
  color: string
}

export type MessageView = {
  id?: string
  type: ProjectMessageRole | 'user_input'
  content: string
  date: Date
  loading: boolean
  subProcessList: []
  status: ProjectMessageStatus
  researchPlanId?: Maybe<string>
  projectId: string
  files: File[]
}

export enum DocumentType {
  SecDocument = 'sec_document',
  GlobalFiling = 'global_filing',
  EquityResearch = 'equity_research',
  Transcript = 'transcript',
  BusinessNews = 'business_news',
  MarketNews = 'market_news',
  PressRelease = 'press_release',
  ExpertInterview = 'expert_interview',
  OtherPublications = 'other_publications',
  InternalDoc = 'internal_doc',
  InternalData = 'internal_data',
  Other = 'other'
}

export type DocumentMetadata = {
  company_name?: string
  company_ticker?: string
  doc_type?: string
  year?: number
  quarter?: number
  accession_number?: string
  cik?: string
  period_of_report_date?: string
  filed_as_of_date?: string
  date_as_of_change?: string
  author?: string
  author_firm?: string
  title?: string
  subtitle?: string
  description?: string
  source?: string
  owner?: string
}

export type PromptItem = {
  title: string
  content: string
}

export type Maybe<T> = T | null | undefined

export type Nullable<T> = T | null

export type TickerListResponse = {
  count: number
  request_id: string
  results: Ticker[]
  status: string
}

export type Ticker = {
  market: string
  name: string
  ticker: string
  type: string
}

export type ApiDocumentUploadResponse = {
  detail: string
  s3_url: string
  document_id: string
}

export type DocumentSourceSelection = DocumentSourceSelectionFolder | DocumentSourceSelectionFile

export type DocumentSourceSelectionFolder = {
  id: string
  sourceId: string
  type: 'folder'
  path: string
  parentPathList: string[]
  title: string
  createdAt: string
  selected: CheckedState
  count: Maybe<number>
  children: DocumentSourceSelection[]
}

export type DocumentSourceSelectionFile = {
  id: string
  sourceId: string
  type: 'file'
  path: string
  parentPathList: string[]
  title: string
  selected: CheckedState
  createdAt: string
}

// export type SourceType = ApiSourceType

export type DocumentSource = {
  id: string
  title: string
  count: number
  order: number
  is_experimental: boolean
  type: SourceType
  selectionState: CheckedState
  description: Maybe<string>
}

export type ApiSource = {
  id: string
  name: string
  updated_at: string
  created_at: string
  order: number
  is_experimental: boolean
  type: SourceType
  meta_data?: {
    apideck_id?: Maybe<string>
    file_count?: Maybe<number>
    description?: Maybe<string>
  }
}

export type ApiSourceFileType = 'file' | 'folder' | 'url'

export type ApiSourceFile = {
  id: string
  created_at: string
  updated_at: string
  name: string
  path: string
  type: ApiSourceFileType
  source_id: string
  parent_folders: string[]
  meta_data?: {
    apideck_id?: Maybe<string>
    file_count?: Maybe<number>
    file_size?: Maybe<number>
    content_type?: Maybe<string>
    file_extension?: Maybe<string>
  }
}

export type ApiSourceSelection = {
  include: string[]
  exclude: string[]
}

export type ChatFilesViewMode = 'default' | 'search'

export type ActiveSelection = {
  name: string
  path: string[]
}

export type ApiProject = {
  id: string
  title?: Maybe<string>
  created_at: string
  updated_at: string
  research_plans: ResearchPlan[]
}

export type SortOrder = 'asc' | 'desc'

export type ProjectListFilter = {
  search?: string
}

export type PageNumber = number

export type PaginatedList<T> = [PageNumber, T[]][]

export type ProjectFilterValue = {
  name: Maybe<string>
}

export type ProjectListSorting = {
  sortBy: keyof Project
  sortOrder: SortOrder
}

export type ApiProjectMessage = {
  id?: string
  content: string
  project_id: string
  created_at?: string
  role: ProjectMessageRole
  status: ProjectMessageStatus
  updated_at?: string
  meta_data?: Maybe<ApiProjectMessageMetaData>
}

export type ApiProjectMessageMetaData = {
  research_plan_id?: Maybe<string>
  new_project_title?: Maybe<string>
}

export type ResearchSuggestion = {
  id?: string
  content: string
  title: string
  created_at?: string
  updated_at?: string
  category?: Maybe<string>
}

export type ProjectMessageRole = 'user' | 'assistant' | 'research_assistant'

export type ProjectMessageStatus = 'PENDING' | 'SUCCESS' | 'ERROR'

export type MessageEvent = {
  data: string
}

export type ResearchReport = {
  id?: Maybe<string>
  created_at?: Maybe<string>
  updated_at?: Maybe<string>
  project_id: string
  research_plan_id: string
  title: string
  content: string
  attachments?: Maybe<ResearchReportAttachment[]>
  sources?: Maybe<ResearchReportSource[]>
}

export type ResearchReportAttachment = {
  filetype: string
  url: string
}

export type ResearchReportSource = {
  content: string
  order: number
  url?: Maybe<string>
}

export type ResearchPlan = {
  id?: Maybe<string>
  created_at?: Maybe<string>
  updated_at?: Maybe<string>
  project_id: string
  content: string
  user_message?: Maybe<string>
  status: ResearchPlanStatus
  started_at?: Maybe<string>
  cancelled_at?: Maybe<string>
  completed_at?: Maybe<string>
}

export type ResearchPlanStatus = 'PENDING' | 'IN_PROGRESS' | 'COMPLETED' | 'CANCELLED' | 'FAILED'

export type ApiResearchMessage = { id?: Maybe<string>; content: string }

export type ApiScheduledResearchPlan = {
  id?: Maybe<string>
  created_at?: Maybe<string>
  updated_at?: Maybe<string>
  project_id: string
  parent_research_plan_id: string
  last_research_plan_id?: Maybe<string>
  schedule: string
  parent_research_plan: ResearchPlan
  last_research_plan?: Maybe<ResearchPlan>
}

export type ScheduledResearhPlan = {
  id: string
  updatedAt: Maybe<Date>
  schedule: string
  title: Maybe<string>
  status: Maybe<ResearchPlanStatus>
  projectId: string
}

export type ProjectApiKey = [
  baseUrl: Maybe<string>,
  sortBy: Maybe<keyof ApiProject>,
  sortOrder: Maybe<SortOrder>,
  page: number,
  searching: Maybe<ProjectListFilter>,
  authToken: Maybe<string>
]

export enum Agent {
  FinancialDataAgent = 'octagon-financials-agent',
  NewsAgent = 'octagon-news-agent',
  SECFilingsAgent = 'octagon-sec-agent',
  StockDataAgent = 'octagon-stock-data-agent ',
  TranscriptsAgent = 'octagon-transcripts-agent',
  WebSearchAgent = 'octagon-web-search-agent',
  CrawlerAgent = 'octagon-scraper-agent'
}

export type Option<T = string> = { value: T; label: string }

export type ApiDataGroup = {
  id: string
  name: string
  created_at: string
  updated_at: string
}

export type ApiDataSet = {
  id: string
  data_group_id: string
  name: string
  created_at: string
  updated_at: string
  row_count: DataSetResponse['row_count']
  last_altered: DataSetResponse['last_altered']
}
