import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { Agent } from '@/types'
import { ReactRenderer } from '@tiptap/react'
import { map } from 'ramda'
import tippy, { Instance } from 'tippy.js'
import { match } from 'ts-pattern'

export const allowedAgentList = Object.values(Agent).filter((item) =>
  match<Agent, boolean>(item)
    .with(Agent.CrawlerAgent, () => true)
    .with(Agent.FinancialDataAgent, () => true)
    .with(Agent.NewsAgent, () => true)
    .with(Agent.SECFilingsAgent, () => true)
    .with(Agent.StockDataAgent, () => true)
    .with(Agent.TranscriptsAgent, () => true)
    .with(Agent.WebSearchAgent, () => true)
    .exhaustive()
)

export function createAgentSuggestion() {
  return {
    char: '@',
    items: ({ query }: any) => {
      return allowedAgentList.filter((item) => item.toLowerCase().startsWith(query.toLowerCase()))
    },

    render: () => {
      let reactRenderer: ReactRenderer
      let popup: Instance

      return {
        onStart: (props: any) => {
          if (!props.clientRect) {
            return
          }

          reactRenderer = new ReactRenderer(
            (mentionListProps: any) => {
              const { items, range } = mentionListProps

              function handleItemClick(item: string) {
                props.editor.commands.deleteRange({ ...range, to: range.to - 1 })
                return props.command({ id: item })
              }

              return (
                <Card>
                  <CardContent className="flex flex-col p-0">
                    {map(
                      (item) => (
                        <Button
                          key={item}
                          variant="ghost"
                          className="w-full rounded-none justify-start"
                          onClick={() => handleItemClick(item)}
                        >
                          {item}
                        </Button>
                      ),
                      (items as any[]).sort()
                    )}
                  </CardContent>
                </Card>
              )
            },
            {
              props,
              editor: props.editor
            }
          )

          popup = tippy(document.body, {
            getReferenceClientRect: props.clientRect as () => DOMRect,
            appendTo: () => document.body,
            content: reactRenderer.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start'
          })
        },

        onUpdate(props: any) {
          reactRenderer.updateProps(props)

          if (!props.clientRect) {
            return
          }

          popup.setProps({
            getReferenceClientRect: props.clientRect as () => DOMRect
          })
        },

        onKeyDown(props: any) {
          if (props.event.key === 'Escape') {
            popup.hide()
            return true
          }
          const elem = reactRenderer.ref as any
          return elem?.onKeyDown(props)
        },

        onExit() {
          popup.destroy()
          reactRenderer.destroy()
        }
      }
    }
  }
}
