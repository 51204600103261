import { useRefSize } from '@/hooks/use-ref-size'
import {
  ActiveSelection,
  ApiSourceFile,
  ChatFilesViewMode,
  DocumentSource,
  DocumentSourceSelection,
  DocumentSourceSelectionFile,
  DocumentSourceSelectionFolder
} from '@/types'
import { CheckedState } from '@radix-ui/react-checkbox'
import { ArrowLeft, PlusCircle, RefreshCcw, Trash } from 'lucide-react'
import { always, map } from 'ramda'
import { useContext, useState } from 'react'
import { SourceBreadcrumb } from './chat-document-breadcrumb'
import { DocumentFileItem } from './chat-document-file-item'
import { DocumentFolderItem } from './chat-document-folder-item'
import { CreateFolderDialog, CreateFolderFormValue } from './create-folder-dialog'
import { SearchInput } from './search-input'
import { Button } from './ui/button'
import { Checkbox } from './ui/checkbox'
import { Label } from './ui/label'
import { ScrollArea } from './ui/scroll-area'
import { Separator } from './ui/separator'
import { UploadSourcesDialog } from './upload-sources-dialog'
import { cn } from '@/lib/utils'
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from './ui/dialog'
import { match } from 'ts-pattern'
import {
  ChatDocumentsSearchFormContext,
  ChatDocumentsSearchFormFunctionsContext
} from './chat-documents-search-form-provider'
import { SourcesPageReadOnlyContext } from '@/contexts/sources-page-readonly'
import { SourceType } from '@/api/octagon-api-generated'

type ChatDocumentsProps = {
  selection: ActiveSelection
  selectionList: DocumentSourceSelection[]
  breadcrumbList: ActiveSelection[]
  selectAll: CheckedState
  viewMode: ChatFilesViewMode
  source: DocumentSource
  isRefreshLoading: boolean
  onSelectionChange(item: DocumentSourceSelectionFolder): void
  onBreadcrumbChange(item: ActiveSelection): void
  onSelectionCheckedChange(item: DocumentSourceSelection, value: CheckedState): void
  onSelectAllChange(value: CheckedState): void
  onCreateFolder(value: CreateFolderFormValue): void
  onDeleteFolder(value: DocumentSourceSelectionFolder): void
  onDeleteFile(value: DocumentSourceSelectionFile): void
  onUploaded(documentList: ApiSourceFile[]): void
  onOnlyClick(value: DocumentSourceSelection): void
  onSearchSubmit(value: string): void
  onBackClick(): void
  onRefreshClick(): void
}

export function ChatDocuments({
  selection,
  selectionList,
  breadcrumbList,
  selectAll,
  viewMode,
  source,
  isRefreshLoading,
  onSelectionChange,
  onBreadcrumbChange,
  onSelectionCheckedChange,
  onSelectAllChange,
  onCreateFolder,
  onDeleteFile,
  onDeleteFolder,
  onUploaded,
  onOnlyClick,
  onSearchSubmit,
  onBackClick,
  onRefreshClick
}: ChatDocumentsProps) {
  const readOnly = useContext(SourcesPageReadOnlyContext)
  const [createFolderOpen, setCreateFolderOpen] = useState(false)

  const { ref: documentListContainerRef, height: documentListHeight } = useRefSize<HTMLDivElement>()

  function handleCreateFolderSubmit(value: CreateFolderFormValue) {
    setCreateFolderOpen(false)
    onCreateFolder(value)
  }

  const [deleteItem, setDeleteItem] = useState<DocumentSourceSelection>()
  const [deleteOpen, setDeleteOpen] = useState(false)
  function handleDeleteOpenChange(value: boolean) {
    setDeleteOpen(value)
  }
  function handleItemDelete(item: DocumentSourceSelection) {
    setDeleteOpen(true)
    setDeleteItem(item)
  }
  function handleDeleteItemSubmit() {
    setDeleteOpen(false)
    switch (deleteItem?.type) {
      case 'file':
        onDeleteFile(deleteItem)
        break
      case 'folder':
        onDeleteFolder(deleteItem)
        break
      default:
        break
    }
  }

  const searchFormFunctions = useContext(ChatDocumentsSearchFormFunctionsContext)

  return (
    <div className="flex flex-col flex-grow p-3 bg-white rounded col-span-3">
      <div className="flex gap-3">
        <Button variant="secondary" className="md:hidden" onClick={onBackClick}>
          <ArrowLeft size={16} className="mr-2" />
          Back
        </Button>
        <ChatDocumentsSearchForm onSubmit={onSearchSubmit} />
        {source.type === 'drive' && (
          <Button variant="outline" size="icon" onClick={onRefreshClick} className="flex-shrink-0">
            <RefreshCcw size={16} className={cn(isRefreshLoading && 'animate-spin')} />
          </Button>
        )}
      </div>

      {match(viewMode)
        .with('default', () => (
          <>
            <Separator className="my-3" />
            <SourceBreadcrumb source={source} breadcrumbList={breadcrumbList} onChange={onBreadcrumbChange} />
          </>
        ))
        .with('search', always(null))
        .exhaustive()}

      <Separator className="my-3" />

      <div className="flex items-center min-h-10">
        {!readOnly && (
          <>
            <Checkbox
              id="select-all-checkbox"
              className="mr-2"
              checked={selectAll}
              onCheckedChange={onSelectAllChange}
            />
            <Label htmlFor="select-all-checkbox" className="max-md:hidden">
              Select all
            </Label>
            <Label htmlFor="select-all-checkbox" className="md:hidden">
              All
            </Label>
          </>
        )}
        <div className="flex-grow"></div>

        {match([viewMode, source.type])
          .with(['default', SourceType.Public], ['default', SourceType.Sql], ['default', SourceType.Uploads], () => (
            <>
              <CreateFolderDialog
                open={createFolderOpen}
                onOpenChange={(value) => setCreateFolderOpen(value)}
                onSubmit={handleCreateFolderSubmit}
              >
                <Button variant="ghost">Create folder</Button>
              </CreateFolderDialog>

              <UploadSourcesDialog
                path={`/${[...selection.path, selection.name].join('/')}`}
                sourceId={source.id}
                onUploaded={onUploaded}
              >
                <Button variant="ghost">
                  <PlusCircle size={16} className="mr-2" />
                  Upload Files
                </Button>
              </UploadSourcesDialog>
            </>
          ))
          .with(
            ['default', SourceType.Drive],
            ['search', SourceType.Drive],
            ['search', SourceType.Public],
            ['search', SourceType.Sql],
            ['search', SourceType.Uploads],
            always(null)
          )
          .exhaustive()}

        <Dialog open={deleteOpen} onOpenChange={handleDeleteOpenChange}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Delete "{deleteItem?.title}"?</DialogTitle>
            </DialogHeader>
            <DialogFooter style={{ justifyContent: 'space-between' }}>
              <Button variant="destructive" onClick={handleDeleteItemSubmit}>
                <Trash className="mr-1" size={16} /> Yes, Delete It
              </Button>
              <DialogClose asChild>
                <Button>No, Save It</Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>

      {match(source.type)
        .with(SourceType.Drive, always(null))
        .with(SourceType.Public, SourceType.Sql, SourceType.Uploads, () => <Separator className="my-3" />)
        .exhaustive()}

      <div className={cn('flex-grow relative')} ref={documentListContainerRef}>
        <ScrollArea className="pr-2" style={{ height: documentListHeight }}>
          <div>
            {map((item) => {
              switch (item.type) {
                case 'folder':
                  return (
                    <DocumentFolderItem
                      key={item.id}
                      item={item}
                      source={source}
                      showOnly={viewMode === 'default'}
                      readOnly={readOnly}
                      onCheckedChange={onSelectionCheckedChange}
                      onSelect={(item) => {
                        onSelectionChange(item)

                        // clear search
                        searchFormFunctions.setValue('')
                      }}
                      onDelete={handleItemDelete}
                      onOnlyClick={onOnlyClick}
                    />
                  )
                case 'file':
                  return (
                    <DocumentFileItem
                      key={item.id}
                      item={item}
                      source={source}
                      showOnly={viewMode === 'default'}
                      readOnly={readOnly}
                      onCheckedChange={onSelectionCheckedChange}
                      onDelete={handleItemDelete}
                      onOnlyClick={onOnlyClick}
                    />
                  )
                default:
                  return null
              }
            }, selectionList)}
          </div>
        </ScrollArea>
      </div>
    </div>
  )
}

type ChatDocumentsSearchFormProps = {
  onSubmit(value: string): void
}

function ChatDocumentsSearchForm({ onSubmit }: ChatDocumentsSearchFormProps) {
  const value = useContext(ChatDocumentsSearchFormContext)
  const functions = useContext(ChatDocumentsSearchFormFunctionsContext)

  return (
    <SearchInput
      value={value}
      className="w-full"
      placeholder="Search"
      onChange={(item: string) => functions.setValue(item)}
      onSubmit={onSubmit}
      onClear={() => functions.setValue('')}
    />
  )
}
