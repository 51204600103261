import SyntaxHighlighter from 'react-syntax-highlighter'

import {
  createDynamicSectionFetcher,
  deleteDynamicSectionFetcher,
  duplicateDynamicSectionFetcher,
  exportDynamicReportToPdfFetcher,
  exportDynamicSectionToCsvFetcher,
  getDynamicReportFetcher,
  getDynamicSectionListFetcher,
  reorderDynamicSectionFetcher,
  submitDynamicSectionFetcher,
  updateDynamicReportTitleFetcher,
  updateDynamicSectionFetcher,
  updateDynamicSectionTitleFetcher
} from '@/api/fetcher'
import { DataTypeEnum, DynamicSection, DynamicSectionStatusEnum } from '@/api/octagon-api-generated'
import { AuthContext } from '@/components/auth-provider'
import { PageLayout } from '@/components/page-layout'
import { PageLoader } from '@/components/page-loader'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet'
import { Spinner } from '@/components/ui/spinner'
import { Textarea } from '@/components/ui/textarea'
import { useToast } from '@/components/ui/use-toast'
import {
  arrayControlTester,
  ArrayRenderer,
  booleanControlTester,
  CheckboxRenderer,
  dateControlTester,
  DateRenderer,
  dateTimeControlTester,
  DateTimeRenderer,
  enumControlTester,
  EnumRenderer,
  getNodeDefaultValue,
  HorizontalLayoutRenderer,
  horizontalLayoutTester,
  InputRenderer,
  integerControlTester,
  IntegerRenderer,
  labelTester,
  LebelRenderer,
  numberControlTester,
  NumberRenderer,
  objectControlTester,
  ObjectRenderer,
  stringControlTester,
  timeControlTester,
  TimeRenderer,
  VerticalLayoutRenderer,
  verticalLayoutTester
} from '@/features/dynamic-report/form/jsonform-renderers'
import { useEnv } from '@/hooks/use-env'
import { Chart } from '@/lib/chart'
import { createMarkdownRenderer } from '@/lib/markdown'
import { cn } from '@/lib/utils'
import { Maybe } from '@/types'
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { zodResolver } from '@hookform/resolvers/zod'
import { JsonSchema, UISchemaElement } from '@jsonforms/core'
import { JsonForms } from '@jsonforms/react'
import { captureException } from '@sentry/react'
import { ChartConfiguration } from 'chart.js'
import { Grid } from 'gridjs'
import 'gridjs/dist/theme/mermaid.css'
import debounce from 'lodash.debounce'
import { Check, Code, Copy, Download, Filter, GripVertical, Menu, Pencil, Plus, Settings, Trash2 } from 'lucide-react'
import { defaultTo, findIndex, isEmpty, isNil, map, update } from 'ramda'
import { Fragment, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { match } from 'ts-pattern'
import { z } from 'zod'
import { useMount } from '@/hooks/use-mount'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { Skeleton } from '@/components/ui/skeleton'

type ReportSectionCommonFields = {
  id: string
  name: string
  rankOrder: number
  formJsonSchema: DynamicSection['form_json_schema']
  formUiSchema: DynamicSection['form_ui_schema']
  formData: DynamicSection['form_data']
  code: Maybe<string>
  status: DynamicSectionStatusEnum
  prompt: Maybe<string>
}

type ReportSectionChart = ReportSectionCommonFields & {
  type: 'chart'
  config: string
}

type ReportSectionTable = ReportSectionCommonFields & {
  type: 'table'
  config: string
}

type ReportSectionText = ReportSectionCommonFields & {
  type: 'text'
  content: string
}

type ReportSection = ReportSectionChart | ReportSectionTable | ReportSectionText

export function DynamicFormResultPage() {
  const params = useParams()

  const id: Maybe<string> = params.id ? decodeURIComponent(params.id) : null

  const auth = useContext(AuthContext)
  const env = useEnv()

  function getDynamicReportCacheKey() {
    const dynamicReportCacheKey = [env.APP_API_BASE_URL, '/api/dynamic/reports', id, auth?.session?.access_token]
    return id && auth?.session?.access_token ? dynamicReportCacheKey : null
  }

  const getDynamicReportApi = useSWR(getDynamicReportCacheKey(), getDynamicReportFetcher, {
    revalidateOnFocus: false
  })

  const updateDynamicReportTitleApi = useSWRMutation(getDynamicReportCacheKey(), updateDynamicReportTitleFetcher)

  const reportId = getDynamicReportApi.data?.id

  function getDynamicSectionListCacheKey() {
    const dynamicSectionCacheKey = [
      env.APP_API_BASE_URL,
      '/api/dynamic/sections',
      reportId,
      auth?.session?.access_token
    ]
    return reportId && auth?.session?.access_token ? dynamicSectionCacheKey : null
  }

  const getDynamicSectionListApi = useSWR(getDynamicSectionListCacheKey(), getDynamicSectionListFetcher, {
    revalidateOnFocus: false,
    onSuccess(data) {
      try {
        const itemList: ReportSection[] = defaultTo([], data)
          .map(mapDynamicSectionToView)
          .filter((item): item is ReportSection => !isNil(item))
          .sort((a, b) => a.rankOrder - b.rankOrder)
        setReportSectionList(() => itemList)
      } catch (e) {
        console.error(e)
      }
    }
  })

  const [sectionLoading, setSectionLoading] = useState<Set<string>>(new Set())
  const [sectionDownloadLoading, setSectionDownloadLoading] = useState<Set<string>>(new Set())

  const createDynamicSectionApi = useSWRMutation(getDynamicSectionListCacheKey(), createDynamicSectionFetcher)

  const submitDynamicSectionApi = useSWRMutation(getDynamicSectionListCacheKey(), submitDynamicSectionFetcher)

  const reorderDynamicSectionApi = useSWRMutation(getDynamicSectionListCacheKey(), reorderDynamicSectionFetcher)

  const updateDynamicSectionApi = useSWRMutation(getDynamicSectionListCacheKey(), updateDynamicSectionFetcher)

  const updateDynamicSectionTitleApi = useSWRMutation(getDynamicSectionListCacheKey(), updateDynamicSectionTitleFetcher)

  const deleteDynamicSectionApi = useSWRMutation(getDynamicSectionListCacheKey(), deleteDynamicSectionFetcher)

  const duplicateDynamicSectionApi = useSWRMutation(getDynamicSectionListCacheKey(), duplicateDynamicSectionFetcher)

  const exportDynamicReportToPdfApi = useSWRMutation(getDynamicSectionListCacheKey(), exportDynamicReportToPdfFetcher)

  const exportDynamicSectionToCsvApi = useSWRMutation(getDynamicSectionListCacheKey(), exportDynamicSectionToCsvFetcher)

  const toast = useToast()

  const [reportSectionList, setReportSectionList] = useState<ReportSection[]>([])

  const [activeReportSection, setActiveReportSection] = useState<ReportSection | null>(null)

  const pageLoading = isEmpty(reportSectionList)

  useMount(() => {
    const client = auth?.client
    if (!client) {
      return
    }
    const subscription = client
      .channel('dynamic_sections_events')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'dynamic_sections',
          filter: `dynamic_report_id=eq.${id}`
        },
        () => {
          getDynamicSectionListApi.mutate()
        }
      )
      .subscribe()

    return function () {
      if (subscription) {
        subscription.unsubscribe()
      }
    }
  })

  useMount(() => {
    const client = auth?.client
    if (!client) {
      return
    }
    const subscription = client
      .channel('dynamic_reports_events')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'dynamic_reports',
          filter: `id=eq.${id}`
        },
        () => {
          getDynamicReportApi.mutate()
        }
      )
      .subscribe()

    return function () {
      if (subscription) {
        subscription.unsubscribe()
      }
    }
  })

  async function handleAddReportSection(prompt: string) {
    if (!reportId) {
      return
    }

    await createDynamicSectionApi.trigger(
      {
        prompt
      },
      {
        revalidate: true
      }
    )
  }

  async function handleUpdateReportSectionSubmit(prompt: string, item: ReportSection) {
    setSectionLoading((prev) => {
      prev.add(item.id)
      return new Set(prev)
    })
    await updateDynamicSectionApi.trigger({ sectionId: item.id, prompt }, { revalidate: true })
    setSectionLoading((prev) => {
      prev.delete(item.id)
      return new Set(prev)
    })
  }

  async function handleDeleteReportSectionSubmit(item: ReportSection) {
    setSectionLoading((prev) => {
      prev.add(item.id)
      return new Set(prev)
    })
    await deleteDynamicSectionApi.trigger({ sectionId: item.id }, { revalidate: true })
    setSectionLoading((prev) => {
      prev.delete(item.id)
      return new Set(prev)
    })
  }

  async function handleDuplicateReportSection(item: ReportSection) {
    setSectionLoading((prev) => {
      prev.add(item.id)
      return new Set(prev)
    })
    await duplicateDynamicSectionApi.trigger({ sectionId: item.id }, { revalidate: true })
    setSectionLoading((prev) => {
      prev.delete(item.id)
      return new Set(prev)
    })
  }

  async function handleExportToPdf() {
    if (!reportId) {
      return
    }
    const pdfUrl = await exportDynamicReportToPdfApi.trigger(
      {},
      {
        onError() {
          toast.toast({
            title: 'Error',
            description: 'Failed to export to PDF',
            variant: 'destructive'
          })
        },
        revalidate: false
      }
    )
    window.open(pdfUrl.s3_url, '_blank')
  }

  async function handleSectionDownload(value: ReportSection) {
    setSectionDownloadLoading((prev) => {
      prev.add(value.id)
      return new Set(prev)
    })
    const data = await exportDynamicSectionToCsvApi.trigger(
      { sectionId: value.id },
      {
        onError() {
          toast.toast({
            title: 'Error',
            description: 'Failed to export to PDF',
            variant: 'destructive'
          })
          setSectionDownloadLoading((prev) => {
            prev.delete(value.id)
            return new Set(prev)
          })
        },
        onSuccess() {
          setSectionDownloadLoading((prev) => {
            prev.delete(value.id)
            return new Set(prev)
          })
        },
        revalidate: false
      }
    )
    window.open(data.s3_url, '_blank')
  }

  const handleDynamicSectionClick = useCallback((item: ReportSection) => {
    setActiveReportSection(item)
    scrollSectionIntoView(item)
  }, [])

  function handleSubmitDynamicSection(formData: unknown, _: ReportSection) {
    if (!activeReportSection) {
      return
    }
    submitDynamicSectionApi.trigger(
      { sectionId: activeReportSection.id, formData },
      {
        revalidate: true
      }
    )
  }

  async function handleReportSectionReorder(
    items: ReportSection[],
    oldItem: Maybe<ReportSection>,
    newItem: Maybe<ReportSection>
  ) {
    setReportSectionList(items)
    if (isNil(oldItem) || isNil(newItem)) {
      return
    }

    await Promise.all([
      reorderDynamicSectionApi.trigger({ sectionId: oldItem.id, rankOrder: newItem.rankOrder }),
      reorderDynamicSectionApi.trigger({ sectionId: newItem.id, rankOrder: oldItem.rankOrder })
    ])
  }

  async function handleSectionTitleSubmit(value: TextEditableFormValue, section: ReportSection) {
    await updateDynamicSectionTitleApi.trigger(
      { sectionId: section.id, title: value.name },
      {
        revalidate: true
      }
    )
    setReportSectionList((prev) => {
      const index = findIndex((item) => item.id === section.id, prev)
      const nextValue: ReportSection = { ...prev[index], name: value.name }
      const nextData = update(index, nextValue, prev)
      return nextData
    })
  }

  async function handleReportTitleSubmit(value: TextEditableFormValue) {
    await updateDynamicReportTitleApi.trigger(
      { title: value.name },
      {
        revalidate: true,
        optimisticData: { ...getDynamicReportApi.data, title: value.name }
      }
    )
  }

  if (isNil(id)) {
    return (
      <PageLayout>
        <div>Dynamic Form result not found</div>
      </PageLayout>
    )
  }

  if (getDynamicReportApi.isLoading || getDynamicSectionListApi.isLoading) {
    return (
      <PageLayout>
        <PageLoader />
      </PageLayout>
    )
  }

  if (pageLoading) {
    return (
      <PageLayout>
        <div className="flex flex-col h-full bg-white rounded p-4 overflow-y-hidden">
          {Array.from({ length: 20 }).map((_, index) =>
            index % 2 === 0 ? (
              <Fragment key={index}>
                <Skeleton
                  className={cn('h-6 w-1/4 mb-4 shrink-0')}
                  style={{ width: `${Math.round(Math.random() * 100)}%` }}
                />
                <Skeleton
                  className={cn('h-6 w-1/4 mb-4 shrink-0')}
                  style={{ width: `${Math.round(Math.random() * 100)}%` }}
                />
              </Fragment>
            ) : (
              <Skeleton key={index} className="h-8 w-1/2 mb-4 shrink-0" />
            )
          )}
        </div>
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      <div className="flex h-full">
        <div className="grid grid-cols-8 flex-row flex-nowrap grow overflow-y-auto gap-4">
          <div className="hidden lg:block col-span-2 bg-white rounded p-4">
            <div className="flex items-center gap-2 mb-4 h-10 justify-between">
              <p className="font-semibold">Report Sections</p>
              <AddReportSectionDialog loading={createDynamicSectionApi.isMutating} onSubmit={handleAddReportSection} />
            </div>

            <DynamicSectionList
              items={reportSectionList}
              activeItem={activeReportSection}
              loading={sectionLoading}
              onItemClick={handleDynamicSectionClick}
              onItemReorder={handleReportSectionReorder}
              onDeleteReportSectionSubmit={handleDeleteReportSectionSubmit}
              onDuplicateReportSection={handleDuplicateReportSection}
            />
          </div>

          <div className="col-span-8 md:col-span-5 lg:col-span-4 bg-white rounded p-4 overflow-y-auto">
            <div className="flex items-start gap-2 mb-4 justify-between">
              <TextEditable
                value={getDynamicReportApi.data?.title ?? ''}
                onSubmit={(formValue) => handleReportTitleSubmit(formValue)}
              />

              <div className="flex items-center gap-2">
                <Sheet>
                  <SheetTrigger asChild className="lg:hidden ">
                    <Button variant="outline" size="icon">
                      <Menu size={14} />
                    </Button>
                  </SheetTrigger>

                  <SheetContent className="w-full">
                    <SheetHeader className="mb-4">
                      <SheetTitle>
                        <p className="font-semibold">Report sections</p>
                      </SheetTitle>
                      <SheetDescription>Reorder and manage sections</SheetDescription>
                    </SheetHeader>

                    <div className="flex justify-end mb-4">
                      <AddReportSectionDialog
                        loading={createDynamicSectionApi.isMutating}
                        onSubmit={handleAddReportSection}
                      />
                    </div>

                    <DynamicSectionList
                      items={reportSectionList}
                      activeItem={activeReportSection}
                      loading={sectionLoading}
                      onItemClick={handleDynamicSectionClick}
                      onItemReorder={handleReportSectionReorder}
                      onDeleteReportSectionSubmit={handleDeleteReportSectionSubmit}
                      onDuplicateReportSection={handleDuplicateReportSection}
                    />
                  </SheetContent>
                </Sheet>

                <Button
                  variant="outline"
                  className="flex gap-2 text-xs"
                  size="sm"
                  onClick={handleExportToPdf}
                  disabled={exportDynamicReportToPdfApi.isMutating}
                >
                  {exportDynamicReportToPdfApi.isMutating ? <Spinner size={14} /> : <Download size={14} />}
                  <span className="hidden md:block">Export to PDF</span>
                </Button>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              {map(
                (item) => (
                  <ReportSectionContainer
                    key={item.id}
                    isActive={activeReportSection?.id === item.id}
                    isDownloadLoading={sectionDownloadLoading.has(item.id)}
                    isFormLoading={
                      submitDynamicSectionApi.isMutating ||
                      [DynamicSectionStatusEnum.PROCESSING, DynamicSectionStatusEnum.PENDING].includes(item.status)
                    }
                    item={item}
                    onClick={handleDynamicSectionClick}
                    onTitleSubmit={handleSectionTitleSubmit}
                    onDownload={handleSectionDownload}
                    onFormSubmit={handleSubmitDynamicSection}
                    onPromptSubmit={handleUpdateReportSectionSubmit}
                  >
                    {match(item)
                      .with({ type: 'text' }, (res) => <ReportSectionText value={res} />)
                      .with({ type: 'chart' }, (res) => <ReportSectionChart value={res} />)
                      .with({ type: 'table' }, (res) => <ReportSectionTable value={res} />)
                      .exhaustive()}
                  </ReportSectionContainer>
                ),
                reportSectionList
              )}
            </div>
          </div>

          <div className="hidden md:block col-span-3 lg:col-span-2 bg-white rounded p-4 overflow-y-auto">
            <div className="flex items-center gap-2 mb-4 justify-between h-10">
              <p className="font-semibold">Update Section</p>
            </div>

            {isNil(activeReportSection) && <div className="text-sm">Section not selected</div>}

            {reportSectionList.map((item) => (
              <div key={item.id} className={cn(activeReportSection?.id !== item.id && 'hidden')}>
                <ReportSectionForm
                  loading={
                    submitDynamicSectionApi.isMutating ||
                    [DynamicSectionStatusEnum.PROCESSING, DynamicSectionStatusEnum.PENDING].includes(item.status)
                  }
                  section={item}
                  onSubmit={(formData) => handleSubmitDynamicSection(formData, item)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </PageLayout>
  )

  function scrollSectionIntoView(item: ReportSection) {
    const el = document.querySelector(`#${mapToHtmlId(item.id)}`)
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' })
    }
  }
}

function mapToHtmlId(value: string) {
  return `section-${value}`
}

type ReportSectionContainerProps = {
  children: ReactNode
  item: ReportSection
  isActive: boolean
  isDownloadLoading: boolean
  isFormLoading: boolean
  onClick(item: ReportSection): void
  onTitleSubmit(value: TextEditableFormValue, item: ReportSection): void
  onPromptSubmit(prompt: string, item: ReportSection): void
  onDownload(item: ReportSection): void
  onFormSubmit(formData: unknown, item: ReportSection): void
}

function ReportSectionContainer({
  children,
  item,
  isActive,
  isDownloadLoading,
  isFormLoading,
  onClick,
  onTitleSubmit,
  onPromptSubmit,
  onDownload,
  onFormSubmit
}: ReportSectionContainerProps) {
  const toast = useToast()
  const [reportSectionFormDialogOpen, setReportSectionFormDialogOpen] = useState(false)

  if ([DynamicSectionStatusEnum.PROCESSING, DynamicSectionStatusEnum.PENDING].includes(item.status)) {
    return (
      <div
        id={mapToHtmlId(item.id)}
        className={cn(
          'p-4 rounded border flex items-center gap-2 text-sm',
          isActive && 'outline-2 outline-primary-foreground outline'
        )}
        onClick={() => onClick(item)}
      >
        <Spinner size={14} />
        <span>Generating AI-powered insights...</span>
      </div>
    )
  }

  return (
    <div
      id={mapToHtmlId(item.id)}
      className={cn('p-4 rounded border', isActive && 'outline-2 outline-primary-foreground outline')}
      onClick={() => onClick(item)}
    >
      <div className="flex items-center gap-2 mb-3">
        <TextEditable value={item.name} onSubmit={(formValue) => onTitleSubmit(formValue, item)} />

        <div className="grow" />

        <UpdateReportSectionDialog
          section={item}
          onSubmit={(prompt) => {
            onPromptSubmit(prompt, item)
          }}
        />

        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              className="flex gap-2 text-xs shrink-0"
              size="sm"
              onClick={() => onDownload(item)}
              disabled={isDownloadLoading}
            >
              {isDownloadLoading ? <Spinner size={14} /> : <Download size={14} />}
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Download section data</p>
          </TooltipContent>
        </Tooltip>

        {!isNil(item.code) && (
          <Popover>
            <PopoverTrigger asChild>
              <Button
                size="icon"
                variant="ghost"
                className="shrink-0"
                onClick={(e) => {
                  // do not select section when clicking on code button
                  e.stopPropagation()
                }}
              >
                <Code size={16} />
              </Button>
            </PopoverTrigger>
            <PopoverContent align="end" className="w-auto">
              <div className="flex flex-row items-center gap-2 p-1">
                <p className="font-semibold text-sm">Dynamic section code</p>

                <div className="grow" />

                <Button
                  size="sm"
                  variant="outline"
                  className="shrink-0 gap-2"
                  onClick={async (e) => {
                    e.stopPropagation()
                    if (isNil(item.code)) {
                      return
                    }
                    try {
                      await navigator.clipboard.writeText(item.code)
                      toast.toast({
                        variant: 'default',
                        title: 'Copied to clipboard',
                        description: 'The code has been copied to your clipboard',
                        duration: 2000
                      })
                    } catch (error) {
                      captureException(error)
                    }
                  }}
                >
                  <Copy size={14} />
                  <span className="hidden md:block text-xs">Copy</span>
                </Button>
              </div>
              <SyntaxHighlighter
                className="text-xs w-[300px] max-h-[400px] lg:w-[500px] border rounded"
                customStyle={{
                  backgroundColor: 'white'
                }}
                lineNumberStyle={{
                  marginRight: 8,
                  paddingRight: 8,
                  borderRight: '1px solid #e0e0e0'
                }}
                children={item.code}
                showLineNumbers
              />
            </PopoverContent>
          </Popover>
        )}

        <Sheet open={reportSectionFormDialogOpen} onOpenChange={setReportSectionFormDialogOpen}>
          <SheetTrigger asChild className="md:hidden">
            <Button variant="outline" size="icon" disabled={isFormLoading}>
              {isFormLoading ? <Spinner size={14} /> : <Filter size={14} />}
            </Button>
          </SheetTrigger>

          <SheetContent className="w-full">
            <SheetHeader className="mb-4">
              <SheetTitle>Update section</SheetTitle>
              <SheetDescription>Apply changes to the section using the form below</SheetDescription>
            </SheetHeader>

            <div key={item.id} className={cn(!isActive && 'hidden')}>
              <ReportSectionForm
                loading={isFormLoading}
                section={item}
                onSubmit={(formData) => onFormSubmit(formData, item)}
              />
            </div>
          </SheetContent>
        </Sheet>
      </div>
      {children}
    </div>
  )
}

type DynamicSectionListProps = {
  items: ReportSection[]
  activeItem: Maybe<ReportSection>
  loading: Set<string>
  onItemClick(item: ReportSection): void
  onItemReorder(items: ReportSection[], oldItem: Maybe<ReportSection>, newItem: Maybe<ReportSection>): void
  onDeleteReportSectionSubmit(item: ReportSection): void
  onDuplicateReportSection(item: ReportSection): void
}

function DynamicSectionList({
  items,
  activeItem,
  loading,
  onItemClick,
  onItemReorder,
  onDeleteReportSectionSubmit,
  onDuplicateReportSection
}: DynamicSectionListProps) {
  const [activeMenuItem, setActiveMenuItem] = useState<Maybe<ReportSection>>(null)
  const [deleteReportSectionDialogOpen, setDeleteReportSectionDialogOpen] = useState(false)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event

    if (over && active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id)
      const newIndex = items.findIndex((item) => item.id === over.id)
      const oldItem = items.find((item) => item.id === active.id)
      const newItem = items.find((item) => item.id === over.id)
      const newItemList = arrayMove(items, oldIndex, newIndex)
      onItemReorder(newItemList, oldItem, newItem)
    }
  }

  return (
    <div>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <ReportSectionListContainer>
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {items.map((item) => (
              <ReportSectionItem
                loading={
                  loading.has(item.id) ||
                  [DynamicSectionStatusEnum.PROCESSING, DynamicSectionStatusEnum.PENDING].includes(item.status)
                }
                isActive={activeItem?.id === item.id}
                value={item}
                key={item.id}
                onClick={() => onItemClick(item)}
                onDelete={(item) => {
                  setDeleteReportSectionDialogOpen(true)
                  setActiveMenuItem(item)
                }}
                onDuplicate={(item) => {
                  onDuplicateReportSection(item)
                  setActiveMenuItem(item)
                }}
              />
            ))}
          </SortableContext>
        </ReportSectionListContainer>
      </DndContext>

      <AlertDialog open={deleteReportSectionDialogOpen} onOpenChange={setDeleteReportSectionDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete selected projects</AlertDialogTitle>
            <AlertDialogDescription />
          </AlertDialogHeader>
          Selected projects will be permanently removed
          <Button
            variant="destructive"
            className="max-md:mb-2"
            onClick={() => {
              if (activeMenuItem) {
                onDeleteReportSectionSubmit(activeMenuItem)
                setDeleteReportSectionDialogOpen(false)
              }
            }}
          >
            Delete
          </Button>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}

function ReportSectionText({ value }: { value: ReportSectionText }) {
  const markdownRenderer = useRef(createMarkdownRenderer())
  const markdownClasses = [
    '[&_ul]:list-disc',
    '[&_ul]:pl-6',
    '[&_ol]:list-decimal',
    '[&_ol]:pl-6',
    '[&_h3]:text-xs',
    '[&_h3]:font-semibold',
    '[&_ul_li_>strong]:font-normal',
    '[&_ol_li_>strong]:font-normal',
    '[&_ul_li]:[white-space-collapse:initial]',
    '[&_ol_li]:[white-space-collapse:initial]'
  ]
  return (
    <div className="text-xs" style={{ whiteSpace: 'pre-wrap' }}>
      <div
        className={cn(markdownClasses)}
        dangerouslySetInnerHTML={{ __html: markdownRenderer.current.render(value.content) }}
      />
    </div>
  )
}

function ReportSectionChart({ value }: { value: ReportSectionChart }) {
  const chartRef = useRef<HTMLCanvasElement>(null)
  const chartInstanceRef = useRef<Chart | null>(null)

  useEffect(() => {
    const handleResizeDebounced = debounce(
      () => {
        chartInstanceRef.current?.resize()
      },
      500,
      {
        trailing: true
      }
    )

    window.addEventListener('resize', handleResizeDebounced)

    return () => {
      window.removeEventListener('resize', handleResizeDebounced)
    }
  }, [])

  useEffect(() => {
    if (!chartRef.current) return
    let config: ChartConfiguration = {
      type: 'line',
      data: {
        datasets: []
      }
    }
    try {
      config = {
        ...config,
        ...JSON.parse(value.config)
      }
    } catch (e) {
      captureException(e)
    }
    const chart = new Chart(chartRef.current, config)
    chartInstanceRef.current = chart
    return () => {
      chart.destroy()
    }
  }, [value.config])

  return (
    <div className="relative">
      <canvas className="md:min-h-[200px]" ref={chartRef}></canvas>
    </div>
  )
}

function ReportSectionTable({ value }: { value: ReportSectionTable }) {
  const tableRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!tableRef.current) {
      return
    }

    const grid = new Grid()

    try {
      const config = {
        ...JSON.parse(value.config),
        className: {
          container: 'text-xs',
          header: 'text-xs',
          search: '[&_input.gridjs-input]:text-xs'
        }
      }
      grid.updateConfig(config)
    } catch (error) {
      captureException(error)
      return
    }

    grid.render(tableRef.current)

    return () => {
      grid.destroy()
    }
  }, [value.config])

  return <div ref={tableRef} />
}

type ReportSectionItemProps = {
  value: ReportSection
  isActive: boolean
  loading: boolean
  onClick: (value: ReportSection) => void
  onDelete: (value: ReportSection) => void
  onDuplicate: (value: ReportSection) => void
}

function ReportSectionItem({ value, isActive, loading, onClick, onDelete, onDuplicate }: ReportSectionItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: value.id })
  const [menuOpen, setMenuOpen] = useState(false)

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <div
      className={cn('flex flex-row flex-nowrap items-center group hover:bg-gray-100', isActive && 'bg-gray-100')}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <Button size="icon" variant="ghost" className="mr-2 shrink-0" {...listeners}>
        <GripVertical className="cursor-move active:cursor-grabbing touch-none" size={16} />
      </Button>

      <Button
        variant="ghost"
        className="justify-start grow text-left whitespace-normal min-h-10 h-auto text-xs p-0"
        onClick={() => {
          onClick(value)
        }}
      >
        {value.name}
      </Button>
      <div className="grow" />

      <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
        <DropdownMenuTrigger asChild>
          <Button className="shrink-0" variant="ghost" size="icon" disabled={loading}>
            {loading ? <Spinner size={14} /> : <Settings size={14} />}
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent align="end">
          <DropdownMenuGroup>
            <DropdownMenuItem
              className="flex flex-row items-center gap-2"
              onClick={() => {
                onDelete(value)
                setMenuOpen(false)
              }}
            >
              <Trash2 size={16} />
              Delete
            </DropdownMenuItem>
            <DropdownMenuItem
              className="flex flex-row items-center gap-2"
              onClick={() => {
                onDuplicate(value)
                setMenuOpen(false)
              }}
            >
              <Copy size={16} />
              Duplicate
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

type ReportSectionListContainerProps = {
  children: React.ReactNode
}

function ReportSectionListContainer({ children }: ReportSectionListContainerProps) {
  const { setNodeRef } = useDroppable({ id: 'droppable' })
  return (
    <div ref={setNodeRef} className="flex flex-col">
      {children}
    </div>
  )
}

type ReportSectionFormProps = {
  section: ReportSection
  loading: boolean
  onSubmit: (formData: unknown) => void
}

function ReportSectionForm({ section, loading, onSubmit }: ReportSectionFormProps) {
  type FormState = {
    data: any
    errors: any[]
    jsonSchema: JsonSchema | undefined
    uiSchema: object | undefined
  }

  const defaultFormData = getNodeDefaultValue(section?.formJsonSchema ?? {})

  const formDataInput: Maybe<any> = section?.formData

  const [formState, setFormData] = useState<FormState>(mapSectionToFormState(section))

  useEffect(() => {
    setFormData(() => mapSectionToFormState(section))
  }, [section])

  const [submitted, setSubmitted] = useState(false)

  function mapSectionToFormState(section: ReportSection) {
    return {
      data: formDataInput ?? defaultFormData,
      errors: [],
      jsonSchema: section?.formJsonSchema ?? undefined,
      uiSchema: section?.formUiSchema ?? undefined
    }
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    if (isEmpty(formState.errors)) {
      onSubmit(formState.data)
    }
    setSubmitted(true)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <JsonForms
          uischema={formState.uiSchema as UISchemaElement}
          schema={formState.jsonSchema ?? undefined}
          data={formState.data}
          renderers={[
            { tester: stringControlTester, renderer: InputRenderer },
            { tester: integerControlTester, renderer: IntegerRenderer },
            { tester: numberControlTester, renderer: NumberRenderer },
            { tester: enumControlTester, renderer: EnumRenderer },
            { tester: horizontalLayoutTester, renderer: HorizontalLayoutRenderer },
            { tester: verticalLayoutTester, renderer: VerticalLayoutRenderer },
            { tester: labelTester, renderer: LebelRenderer },
            { tester: dateControlTester, renderer: DateRenderer },
            { tester: timeControlTester, renderer: TimeRenderer },
            { tester: dateTimeControlTester, renderer: DateTimeRenderer },
            { tester: booleanControlTester, renderer: CheckboxRenderer },
            { tester: arrayControlTester, renderer: ArrayRenderer },
            { tester: objectControlTester, renderer: ObjectRenderer }
          ]}
          validationMode="ValidateAndHide"
          additionalErrors={submitted ? formState.errors : []}
          onChange={({ data, errors }) => {
            setFormData((prev) => ({
              ...prev,
              data,
              errors: errors ?? []
            }))
          }}
        />
      </div>

      <div className="flex justify-end">
        <Button type="submit" size="sm" className="text-xs w-20" disabled={loading}>
          {loading ? <Spinner size={16} /> : 'Submit'}
        </Button>
      </div>
    </form>
  )
}

type UpdateReportSectionDialogProps = {
  section: ReportSection
  onSubmit: (prompt: string) => void
}

function UpdateReportSectionDialog({ section, onSubmit }: UpdateReportSectionDialogProps) {
  const [open, setOpen] = useState(false)

  const formSchema = z.object({
    description: z.string().min(1, 'Description is required')
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      description: section.prompt ?? ''
    }
  })

  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    form.reset({
      description: section.prompt ?? ''
    })
  }, [section])

  // useEffect(() => {

  //   if (!textareaRef.current) {
  //     return
  //   }
  //   if (!open) {
  //     return
  //   }
  //   textareaRef.current.focus()
  // }, [open])

  function handleSubmit(value: z.infer<typeof formSchema>) {
    onSubmit(value.description)
    form.reset()
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <Pencil size={14} />
        </Button>
      </DialogTrigger>
      <DialogContent
        className="md:w-[700px] md:max-w-[700px]"
        onClick={(e) => {
          e.stopPropagation()
        }}
        onOpenAutoFocus={(e) => {
          // prevent dialog auto focus on first element
          e.preventDefault()
          const el = textareaRef.current
          if (!el) {
            return
          }
          el.focus()
          // set cursor to end of text because when focus() is called, it sets cursor to start of text
          el.setSelectionRange(el.value.length, el.value.length)
        }}
      >
        <DialogHeader>
          <DialogTitle>Describe ✨</DialogTitle>
          <DialogDescription>
            Describe changes to the form in the text field below. They will be immediately applied to the form.
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Textarea className="mb-4" placeholder="Describe changes..." {...field} ref={textareaRef} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex justify-end gap-2">
              <DialogClose asChild>
                <Button type="button" variant="secondary">
                  Cancel
                </Button>
              </DialogClose>

              <DialogClose asChild disabled={!form.formState.isValid}>
                <Button type="submit">Update</Button>
              </DialogClose>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

type AddReportSectionDialogProps = {
  loading: boolean
  onSubmit: (value: string) => void
}

function AddReportSectionDialog({ loading, onSubmit }: AddReportSectionDialogProps) {
  const formSchema = z.object({
    description: z.string().min(1, 'Description is required')
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      description: ''
    }
  })

  function handleSubmit(value: z.infer<typeof formSchema>) {
    onSubmit(value.description)
    form.reset()
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size="icon" variant="outline" className="shrink-0" disabled={loading}>
          {loading ? <Spinner size={16} /> : <Plus size={16} />}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Section</DialogTitle>
          <DialogDescription>Describe new section in the text field below.</DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Textarea className="mb-4" placeholder="Type in section details..." {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex justify-end">
              <DialogClose asChild disabled={!form.formState.isValid}>
                <Button type="submit">Submit</Button>
              </DialogClose>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

type TextEditableProps = {
  value: string
  onSubmit: (value: TextEditableFormValue) => void
}

const textEditableSchema = z.object({
  name: z.string().min(1).max(100)
})

type TextEditableFormValue = z.infer<typeof textEditableSchema>

function TextEditable({ value, onSubmit }: TextEditableProps) {
  const [readOnly, setReadOnly] = useState(true)
  const form = useForm<TextEditableFormValue>({
    resolver: zodResolver(textEditableSchema),
    defaultValues: { name: value }
  })

  if (readOnly) {
    return (
      <div
        className="text-sm font-semibold w-auto whitespace-nowrap"
        onClick={() => {
          setReadOnly(false)
        }}
      >
        {form.getValues('name')}
        {/* <Button
          type="submit"
          variant="ghost"
          size="icon"
          className="shrink-0"
          onClick={() => {
            setReadOnly(false)
          }}
        >
          <Pencil size={14} />
        </Button> */}
      </div>
    )
  }

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => {
          form.handleSubmit(onSubmit)(e)
          if (!form.getFieldState('name').error) {
            setReadOnly(true)
          }
        }}
        className="flex flex-row items-start w-full"
      >
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <Input
                  style={{ marginTop: 0 }}
                  autoFocus
                  placeholder="Type in"
                  className={cn('text-sm font-semibold pr-10 mt-0')}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button variant="ghost" size="icon" className="shrink-0 -ml-10">
          <Check size={14} />
        </Button>
      </form>
    </Form>
  )
}

function mapDynamicSectionToView(item: DynamicSection) {
  if (isNil(item.id)) {
    return null
  }
  return match(item)
    .with({ data_type: DataTypeEnum.Text }, (res) => ({
      id: res.id,
      name: res.title,
      rankOrder: res.rank_order,
      type: 'text',
      formJsonSchema: res.form_json_schema,
      formUiSchema: res.form_ui_schema,
      formData: res.form_data,
      content: (res.data_content as { content: string })?.content ?? '',
      code: res.sql_query,
      status: res.status,
      prompt: res.prompt
    }))
    .with({ data_type: DataTypeEnum.Chart }, (res) => ({
      id: res.id,
      name: res.title,
      rankOrder: res.rank_order,
      type: 'chart',
      formJsonSchema: res.form_json_schema,
      formUiSchema: res.form_ui_schema,
      formData: res.form_data,
      config: JSON.stringify(res.data_content),
      code: res.sql_query,
      status: res.status,
      prompt: res.prompt
    }))
    .with({ data_type: DataTypeEnum.Table }, (res) => ({
      id: res.id,
      name: res.title,
      rankOrder: res.rank_order,
      type: 'table',
      formJsonSchema: res.form_json_schema,
      formUiSchema: res.form_ui_schema,
      formData: res.form_data,
      config: JSON.stringify(res.data_content),
      code: res.sql_query,
      status: res.status,
      prompt: res.prompt
    }))
    .otherwise(() => null)
}
