import { useMount } from '@/hooks/use-mount'
import { Maybe, MessageView } from '@/types'
import { captureException } from '@sentry/react'
import { User } from '@supabase/supabase-js'
import { append, findIndex, head, isEmpty, map, update } from 'ramda'
import { useState } from 'react'
import { match } from 'ts-pattern'
import { ChatBotMessage, ChatBotMessageLoading } from './chat-bot-message'
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar'
import { useToast } from './ui/use-toast'
import { formatDateAgo } from '@/lib/date'
import { Alert, AlertDescription, AlertTitle } from './ui/alert'
import { AlertCircle } from 'lucide-react'
import Markdown from 'react-markdown'
import { noop } from '@/lib/function'

type ChatUserMessageProps = {
  message: MessageView
  user?: Maybe<User>
}

export function ChatUserMessage({ message, user }: ChatUserMessageProps) {
  return (
    <ContentContainer user={user} message={message}>
      <p
        className="text-sm bg-secondary mb-2 p-4 text-primary rounded"
        style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
      >
        {message.content}
      </p>
    </ContentContainer>
  )
}

type ChatUserInputMessageProps = {
  message: MessageView
  user?: Maybe<User>
  onMessageReceive?(): void
  onMessageCompleted(): void
  onReloadResearch?(prompt: string): void
  onRetryResearch?(item: MessageView): void
  createBotMessage(userMessage: string, files: File[]): Promise<MessageView>
}

class RetriableError extends Error {}
class FatalError extends Error {}

export function ChatUserInputMessage({
  message,
  user,
  onMessageReceive = noop,
  onMessageCompleted,
  onReloadResearch = noop,
  onRetryResearch = noop,
  createBotMessage
}: ChatUserInputMessageProps) {
  const { toast } = useToast()

  const [messageList, setMessageList] = useState<MessageView[]>([])
  const [hasError, setHasError] = useState(false)

  useMount(() => {
    getMessageResponse(message.content)
  })

  async function getMessageResponse(value: string) {
    try {
      const ctrl = new AbortController()

      createBotMessage(value, message.files)
        .then((messageView: MessageView) => {
          if (messageView.status === 'ERROR') {
            onMessageCompleted()
            throw new FatalError(messageView.content)
          }
          handleMessage(messageView)
          if (messageView.status === 'SUCCESS') {
            onMessageCompleted()
            ctrl.abort()
          }
        })
        .catch((e) => {
          setHasError(true)
          if (!(e instanceof RetriableError)) {
            handleMessageError()
          }
          captureException(e)
          throw e
        })
    } catch (e) {
      console.error(e)
    }
  }

  function handleMessage(messageView: MessageView) {
    updateOrAppendMessage(messageView)
    onMessageReceive()
  }

  function updateOrAppendMessage(item: MessageView) {
    setMessageList((prev) => {
      const foundIndex = findIndex(({ id }) => id === item.id, prev)
      if (foundIndex > -1) {
        return update(foundIndex, item, prev)
      }
      return append(item, prev)
    })
  }

  function handleMessageError() {
    setHasError(true)
    toast({
      variant: 'destructive',
      title: 'Oops, something went wrong'
    })
  }

  return (
    <>
      <ContentContainer user={user} message={message}>
        <div
          className="text-sm bg-secondary mb-2 p-4 text-primary rounded"
          style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}
        >
          <Markdown>{message.content}</Markdown>
        </div>
      </ContentContainer>
      {map(
        (item) =>
          match(item.type)
            .with('assistant', 'research_assistant', () => (
              <ChatBotMessage
                key={item.id}
                message={item}
                onReloadResearch={onReloadResearch}
                onRetryResearch={onRetryResearch}
              />
            ))
            .otherwise(() => null),
        messageList
      )}
      {isEmpty(messageList) && !hasError && <ChatBotMessageLoading date={new Date()} />}
      {hasError && (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>There was an error while generating the research plan. Please try again.</AlertDescription>
        </Alert>
      )}
    </>
  )
}

type ContentContainerProps = {
  user?: Maybe<User>
  message: MessageView
  children: React.ReactNode
}

function ContentContainer({ children, user, message }: ContentContainerProps) {
  const userInitials = (user?.user_metadata.full_name ?? '')
    .split(' ')
    .map((item: string) => head(item).toUpperCase())
    .join('')
  const name = user?.user_metadata.full_name
  const nameFallback = userInitials
  const date = message.date
  const dateView = formatDateAgo(date, new Date())
  const image = user?.user_metadata?.picture

  return (
    <div className="mb-6 table-row" data-testid="message-user">
      <div className="table-cell">
        <div className="flex flex-nowrap items-center justify-end gap-2 mb-2">
          <strong className="text-xs">{name}</strong>
          <Avatar className="w-5 h-5">
            <AvatarImage src={image} alt="@shadcn" />
            <AvatarFallback>{nameFallback}</AvatarFallback>
          </Avatar>
        </div>
        {children}
        <p className="text-right text-xs text-slate-500">{dateView}</p>
      </div>
    </div>
  )
}
